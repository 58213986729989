import styled, { css } from 'styled-components';

import { Tooltip } from '@column/column-ui-kit';

export const FormElement = styled.div<{ fullWidth?: boolean; newRow?: boolean }>`
  display: grid;
  grid-gap: 8px;
  position: relative;
  align-content: start;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      grid-column: 1 / 3;
    `}

  ${({ newRow }) =>
    newRow &&
    css`
      grid-column-start: 1;
    `}
`;

export const FormFields = styled.div`
  display: grid;
  grid-gap: 24px;
`;

export const FormLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
  position: relative;

  a {
    color: ${({ theme }) => theme.primary.background};
    text-decoration: none;
    display: table;
    position: absolute;
    right: 0;
    top: 0;
  }

  small {
    line-height: 16px;
    font-size: 12px;
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

export const FormLabelIcon = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;

  ${FormLabel} {
    line-height: 20px;
  }

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.secondary.background};

    display: block;
    margin-top: 2px;
  }
`;

export const FormLabelTooltip = styled(Tooltip)`
  display: inline-block;
  vertical-align: top;
  margin: 2px 0 2px 4px;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(700)};
    --icon-size: 16px;
  }

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(900)};
    }
  }
`;

export const FormText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 0;
  color: ${({ theme }) => theme.foreground};
  word-break: break-all;
`;

export const FormParagraphLight = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const Divider = styled(FormElement)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  &:before,
  &:after {
    content: '';
    height: 1px;
    flex: 1 1;
    background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
  }
`;

export const FormGroup = styled.div`
  display: grid;
  gap: 24px;
  padding-bottom: 24px;
  grid-template-columns: 1fr;
`;

export const FormElementAction = styled.div<{ gap?: number }>`
  display: grid;
  grid-template-columns: auto min-content;
  position: relative;

  ${({ gap }) =>
    gap !== undefined
      ? css`
          gap: ${gap}px;
        `
      : css`
          gap: 8px;
        `};
`;

export const FormFieldStatusWrapper = styled.div`
  position: relative;
`;

export const FormFieldStatus = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
`;

export const FieldStatus = styled.div<{
  show?: boolean;
  color?: 'success' | 'warning' | 'danger' | 'info' | 'pending';
}>`
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  gap: 4px;
  transition:
    opacity 0.2s,
    transform 0.2s;

  svg {
    --icon-size: 16px;
  }

  ${({ show }) =>
    !show &&
    css`
      transform: translateY(-4px);
      opacity: 0;
      pointer-events: none;
    `}

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      transition-delay: 0.15s;
    `}

  ${({ color, theme }) =>
    color === 'success' &&
    css`
      svg {
        --icon-color: ${theme.success.background};
      }
    `}

  ${({ color, theme }) =>
    color === 'warning' &&
    css`
      svg {
        --icon-color: ${theme.warning.background};
      }
    `}

    ${({ color, theme }) =>
    color === 'danger' &&
    css`
      svg {
        --icon-color: ${theme.danger.background};
      }
    `}

    ${({ color, theme }) =>
    color === 'info' &&
    css`
      svg {
        --icon-color: ${theme.primary.background};
      }
    `}

    ${({ color }) =>
    color === 'pending' &&
    css`
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      svg {
        animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    `}
`;
