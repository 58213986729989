export const testDateFormat = (value: string | undefined) => {
  return value && /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i.test(value);
};

export const getDateFormatFromValues = (year: string | number, month: string | number, day: string | number) => {
  return `${year.toString()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const getDateFormat = (date: Date) => {
  return `${date.getFullYear().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

export const checkDateParts = (date: any) => {
  if (typeof date !== 'string') {
    return false;
  }

  const parts = date.split('-');

  if (!testDateFormat(date)) {
    return false;
  }

  if (
    Number(parts[1]) < 13 &&
    Number(parts[1]) > 0 &&
    Number(parts[2]) < 32 &&
    Number(parts[2]) > 0 &&
    Number(parts[0]) > 0
  ) {
    return true;
  }

  return false;
};

export const getDateParts = (date: string) => {
  const parts = date.split('-');

  return { year: parts[0], month: parts[1], day: parts[2] };
};

// Day of the week, Month Day, Year - 00:00 PM

export const getDateText = (object: Date): string => {
  return `${object.toLocaleString('en-US', { weekday: 'long' })}, ${object.toLocaleString('en-US', {
    month: 'long',
  })} ${object.getDate()}, ${object.getFullYear()} - ${object.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}`;
};

// Month Day, Year - 00:00 PM

export const getDateTextShort = (object: Date): string => {
  return `${object.toLocaleString('en-US', {
    month: 'long',
  })} ${object.getDate()}, ${object.getFullYear()} - ${object.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}`;
};

// UTC Date format: YYYY-MM-DD THH:MM +-HH:MM
export const getDateUTC = (timestamp: Date): string => {
  const year = timestamp.getUTCFullYear();
  const month = String(timestamp.getUTCMonth() + 1).padStart(2, '0');
  const day = String(timestamp.getUTCDate()).padStart(2, '0');
  const hours = String(timestamp.getUTCHours()).padStart(2, '0');
  const minutes = String(timestamp.getUTCMinutes()).padStart(2, '0');
  const timeZoneOffset = timestamp.getTimezoneOffset();
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60))
    .toString()
    .padStart(2, '0');
  const timeZoneOffsetMinutes = (Math.abs(timeZoneOffset) % 60).toString().padStart(2, '0');
  const timeZoneSign = timeZoneOffset >= 0 ? '-' : '+';
  const utcString = `${year}-${month}-${day} T${hours}:${minutes} ${timeZoneSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes}`;
  return utcString;
};

// UTC Long Date format: YYYY-MM-DD THH:MM +-HH:MM
export const getDateLongUTC = (time: string | Date): string => {
  const timestamp = new Date(time);
  const year = timestamp.getUTCFullYear();
  const month = timestamp.toLocaleString('en-US', { month: 'long' });
  const weekday = timestamp.toLocaleString('en-US', { weekday: 'long' });
  const day = String(timestamp.getUTCDate()).padStart(2, '0');
  const hours = String(timestamp.getUTCHours()).padStart(2, '0');
  const minutes = String(timestamp.getUTCMinutes()).padStart(2, '0');
  const timeZoneOffset = timestamp.getTimezoneOffset();
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60))
    .toString()
    .padStart(2, '0');
  const timeZoneOffsetMinutes = (Math.abs(timeZoneOffset) % 60).toString().padStart(2, '0');
  const timeZoneSign = timeZoneOffset >= 0 ? '-' : '+';
  const utcString = `${weekday}, ${month} ${day}, ${year} — ${hours}:${minutes} ${timeZoneSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes} UTC`;
  return utcString;
};
