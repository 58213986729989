import { PageBankAccountsEdit } from './Edit';
import { PageBankAccounts } from './Overview';
import { PageBankAccountsStatements } from './Statements';
import { PageBankAccountsTransfers } from './Transfers';

export const BANK_ACCOUNTS = {
  BankAccounts: PageBankAccounts,
  BankAccountsEdit: PageBankAccountsEdit,
  BankAccountsTransfers: PageBankAccountsTransfers,
  BankAccountsStatements: PageBankAccountsStatements,
} as const;
