import React, { createRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Icon, Input, PasswordInput, Tooltip } from '@column/column-ui-kit';

import { SectionMfaSms } from '~/app/pages/User/Personal/SectionMfaSms';
import { SectionMfaTotp } from '~/app/pages/User/Personal/SectionMfaTotp';
import { PageHeader, RenderFields } from '~/components';
import { PendingIcon, PlaneButton } from '~/elements';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { DashboardUser, UserRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FieldStatus, FormElementAction, FormFieldStatus, FormFieldStatusWrapper, Inner } from '~/styles';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

interface CustomDashboardUser extends DashboardUser {
  temporaryData?: {
    authenticatorApp?: string;
  };
  newPassword?: string;
  currentPassword?: string;
  newPasswordRepeat?: string;
}

const Resend = styled(Button)`
  min-width: 108px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledEmailInput = styled(Input)`
  padding-right: 124px;
`;

const StyledTooltip = styled(Tooltip)``;

export const PagePersonal: React.FC = () => {
  const { currentUser } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const deviceMFAEnabled = useFeatureFlag(FeatureFlag.EnableDeviceMFA);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const notificationContainer = createRef<HTMLDivElement>();

  const { handleReCaptcha } = reCaptchaCheck();

  const formMethods = useForm<CustomDashboardUser>({
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phoneNumber: currentUser?.phoneNumber,
      email: currentUser?.email,
    },
  });

  const { handleSubmit, setValue, watch, getValues } = formMethods;

  if (!currentUser) {
    return null;
  }

  const handleVerifyEmail = (resend?: boolean) => {
    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .update(
            {
              email: getValues('email'),
            },
            reCaptchaToken
          )
          .then(async () => {
            await currentUser.createVerifyEmail();

            log({
              name: `User email verify${resend ? ' resent' : ''}`,
              context: currentUser,
            });

            if (!resend) {
              return;
            }

            addSuccessNotification({
              content: 'Verification email resent! Check your inbox.',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
        });
      }
    );
  };

  const handleSave = (data: CustomDashboardUser) => {
    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .update(
            {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phoneNumber: data.phoneNumber,
            },
            reCaptchaToken
          )
          .then(() => {
            addSuccessNotification({
              content: 'User data updated',
            });
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
        });
      }
    );

    if (getValues('newPassword')) {
      if (newPassword !== newPasswordRepeat) {
        addDangerNotification({
          content: 'Passwords are not the same',
        });
        return;
      }

      UserRepository.updatePassword({
        newPassword: data.newPassword ?? '',
        currentPassword: data.currentPassword ?? '',
      })
        .then(() => {
          addSuccessNotification({
            content: 'Password changed',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
    }

    if (getValues('newPassword') || getValues('currentPassword') || getValues('newPasswordRepeat')) {
      setValue('currentPassword', '');
      setValue('newPassword', '');
      setValue('newPasswordRepeat', '');
    }
  };

  return (
    <>
      <PageHeader text="Profile" />
      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={handleSubmit(handleSave)}>
          {RenderFields<CustomDashboardUser>({
            sections: [
              {
                headline: 'Personal Information',
                fields: [
                  {
                    id: 'firstName',
                    label: 'First Name',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <Input
                          value={String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="First Name"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'lastName',
                    label: 'Last Name',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <Input
                          value={String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Last Name"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'email',
                    label: 'Email address',
                    rules: { required: true },
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      const isEmailVerified = value === currentUser.email && currentUser.emailState === 'VERIFIED';
                      const isEmailPending =
                        value === currentUser.email && currentUser.emailState === 'VERIFY_IN_PROGRESS';

                      return (
                        <FormElementAction gap={isEmailVerified ? 0 : undefined}>
                          <FormFieldStatusWrapper>
                            <StyledEmailInput
                              value={String(value)}
                              onChange={onChange}
                              onBlur={onBlur}
                              ref={ref}
                              placeholder="Email Address"
                              autoComplete="off"
                              hasError={isTouched && !!error}
                            />

                            <FormFieldStatus ref={notificationContainer}>
                              <FieldStatus show={isEmailPending} color="pending">
                                <StyledTooltip
                                  content="A verification email has been sent. Please check your inbox to complete verification."
                                  placement="top"
                                >
                                  Pending
                                </StyledTooltip>
                                <PendingIcon />
                              </FieldStatus>

                              <FieldStatus show={isEmailVerified} color="success">
                                Verified <Icon.Checkmark />
                              </FieldStatus>
                              <FieldStatus show={!isEmailPending && !isEmailVerified} color="warning">
                                <StyledTooltip
                                  content="Please verify your email address. Verification is required to set up two-factor authentication."
                                  placement="top"
                                >
                                  Not verified
                                </StyledTooltip>
                                <Icon.Warning />
                              </FieldStatus>
                            </FormFieldStatus>
                          </FormFieldStatusWrapper>

                          {!isEmailVerified &&
                            (isEmailPending ? (
                              <Resend
                                type="button"
                                onClick={() => handleVerifyEmail(true)}
                                icon={<Icon.Mail />}
                                variant="secondary"
                              >
                                Resend
                              </Resend>
                            ) : (
                              <PlaneButton
                                onDone={() => handleVerifyEmail()}
                                icon={<Icon.Mail />}
                                success={
                                  <Button onClick={() => handleVerifyEmail()} icon={<Icon.Mail />} variant="secondary">
                                    Resend
                                  </Button>
                                }
                              >
                                Verify
                              </PlaneButton>
                            ))}
                        </FormElementAction>
                      );
                    },
                  },
                ],
              },
              {
                headline: 'Two-factor authentication',
                fields: [
                  {
                    id: 'phoneNumber',
                    label: 'Phone Number',
                    rules: { required: true },
                    children: () => {
                      return <SectionMfaSms onPhoneNumberChange={setPhoneNumber} />;
                    },
                  },
                  {
                    id: 'temporaryData.authenticatorApp',
                    label: 'Authenticator App',
                    hide: !deviceMFAEnabled,
                    children: () => {
                      return deviceMFAEnabled && <SectionMfaTotp />;
                    },
                  },
                ],
              },
              {
                headline: 'Password',
                fields: [
                  {
                    id: 'newPassword',
                    label: 'New Password',
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <PasswordInput
                          value={value === undefined ? '' : String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="New Password"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'newPasswordRepeat',
                    label: 'Repeat Password',
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <PasswordInput
                          value={value === undefined ? '' : String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Repeat Password"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                  {
                    id: 'currentPassword',
                    label: 'Current Password',
                    children: ({ value, onChange, onBlur, ref }, { isTouched, error }) => {
                      return (
                        <PasswordInput
                          value={value === undefined ? '' : String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          placeholder="Current Password"
                          hasError={isTouched && !!error}
                        />
                      );
                    },
                  },
                ],
              },
            ],
          })}

          <Inner>
            <Action>
              <Button type="submit" size="small">
                Save changes
              </Button>
            </Action>
          </Inner>
        </form>
      </FormProvider>
    </>
  );
};
