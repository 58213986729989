import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, SegmentedControl, SegmentedControlVariant } from '@column/column-ui-kit';

import { PageHeader } from '~/components';
import { useNavigate } from '~/lib/navigation';
import { CreateCustomRoleRequestObject } from '~/typings/API';

import { AdditionalPermissions } from './AdditionalPermissions';
import { RoleInfo } from './RoleInfo';
import { TransfersAndAccounts } from './TransfersAndAccounts';

enum Section {
  SectionRoleInfo = 'Role Info',
  SectionTransfersAndAccounts = 'Transfers & Accounts',
  SectionAdditionalPermissions = 'Additional Permissions',
}

export const PageCreateRole: React.FC = () => {
  const navigate = useNavigate();

  const [visibleSection, setVisibleSection] = useState<Section>(Section.SectionRoleInfo);
  const [lastSection, setLastSection] = useState<Section>();
  const sections = useMemo(() => {
    return Object.values(Section).map((section) => ({ label: section, value: section }));
  }, []);
  const handleGoForward = useCallback(() => {
    setVisibleSection((curSection) => {
      if (curSection === Section.SectionRoleInfo) {
        return Section.SectionTransfersAndAccounts;
      }
      return Section.SectionAdditionalPermissions;
    });
  }, [lastSection]);

  const form = useForm<CreateCustomRoleRequestObject>({
    defaultValues: {
      name: '',
      description: '',
      platformLevelPermissions: {
        achCredits: 'read',
        achDebits: 'read',
        achReturns: 'read',
        apiKeys: 'read',
        entities: 'read',
        loans: 'read',
        platformInfo: 'read',
        realtimeTransfers: 'read',
        wireTransfers: 'read',
        depositChecks: 'read',
        issueChecks: 'read',
        bookTransfers: 'read',
        stopPayments: 'read',
        counterparties: 'read',
        bankAccounts: 'read',
        reporting: 'read',
      },
    },
  });

  return (
    <>
      <PageHeader
        text="Create Role"
        tabs={
          <SegmentedControl
            active={visibleSection}
            options={sections}
            onOptionChange={(section) => {
              setLastSection(visibleSection);
              setVisibleSection(section);
            }}
            variant={SegmentedControlVariant.Secondary}
          />
        }
      >
        {visibleSection !== Section.SectionAdditionalPermissions && (
          <Button onClick={handleGoForward} size="small" variant="secondary">
            Next
          </Button>
        )}
        {visibleSection === Section.SectionAdditionalPermissions && <Button size="small">Save Role</Button>}
      </PageHeader>
      <FormProvider {...form}>
        {visibleSection === Section.SectionRoleInfo && <RoleInfo />}
        {visibleSection === Section.SectionTransfersAndAccounts && <TransfersAndAccounts />}
        {visibleSection === Section.SectionAdditionalPermissions && <AdditionalPermissions />}
      </FormProvider>
    </>
  );
};
