import { PlatformType } from '~/models/PlatformModel';
import { InternationalWireBillingConfig } from '~/typings/API';
import { convertValues } from '~/util';

import { client } from './client';
import { AddressType } from './EntityRepository';
import { GoogleFile } from './GoogleFileRepository';
import { financialProducts, financialProductType } from './Platform/financialProducts';

export interface GetPlatformRequest {
  platformId: string;
}

export interface UpdatePlatformRequest {
  platformId: string;
  name?: string;
  reportingTimeZone?: string;
  enableLive?: boolean;
}

export type UpdatePlatform = UpdatePlatformRequest;
export type { financialProductType, InternationalWireBillingConfig };

export { financialProducts };

export type BusinessPageIdType = 'information' | 'location' | 'payment-volume' | 'your-customers' | 'your-information';

export interface PersonType {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  jobTitle?: string;
  ownershipPercentage?: string;
  dateOfBirth?: string;
  email?: string;
  verificationType?: 'ssn' | 'itin' | 'passport';
  ssn?: string;
  itin?: string;
  countryOfIssue?: string;
  passportNumber?: string;
  dateOfIssue?: string;
  dateOfExpiration?: string;
  passportDocumentId?: string;
  address?: AddressType;
  documentaryProof?: GoogleFile;
}

export interface PaymentVolumeType {
  id: string;
  transactions?: string;
  amount?: string;
}

export interface PlatformConfig {
  id: string;
  checkIssuingCustomCheckNumbersEnabled?: boolean;
}

export interface PlatformMeta {
  companyName?: string;
  signUpPurpose?: string;
  goLiveDate?: string;
  financialProductsSelected?: financialProductType[];
  financialProductsText?: string;
  financialProductsSubmitted?: boolean;
  companySize?: string;
  customersSize?: string;
  otherComment?: string;
  contactNumber?: string;
  readyToLaunch?: boolean;
  complianceCapabilities?: string;
  selfPaymentOrigination?: string;
  businessInformationSubmitted?: boolean;
  esignPolicyAccepted?: boolean;
  depositAccountAgreementAccepted?: boolean;
  commercialPurposesAcked?: boolean;
  paymentOriginationAccepted?: boolean;
  customerTypes?: string[];
  customerTypeOther?: string;
  contactToRemoveLimits?: boolean;
  businessTypes?: string[];
  moveMoneyFor?: string[];
  docSubmittedPreRootEntityCreation?: string[];

  // dependency graph node values for onboarding
  depgraphNodeValues?: DepgraphNodeValues;
}

export interface DepgraphNodeValues {
  completedPages?: BusinessPageIdType[];
  clientInstanceId?: string;
  // business information
  customerType?: string;
  entityLegalName?: string;
  entityDbaName?: string;
  entityWebsite?: string;
  legalEntityType?: string;
  entityStateOfIncorporation?: string;
  entityYearOfIncorporation?: string;
  entityCountryOfIncorporation?: string;
  entityRegistrationId?: string;
  sourceOfFunds?: string[];
  descriptionOfPrimarySourcesOfFunds?: string;
  einProof?: GoogleFile;
  entityDetailsDocumentaryProof?: GoogleFile;
  evidenceOfActiveStatus?: GoogleFile;
  businessDocumentationProof?: GoogleFile;
  bankAccountAuthorizationDocument?: GoogleFile;

  // business location
  locationsOfBusinessOperation?: string[];
  entityPhysicalAddress?: AddressType;
  entityPhysicalAddressProof?: GoogleFile;
  entityRegisteredAddress?: AddressType;
  entityMailingAddress?: AddressType;

  // business customers
  typesOfCustomers?: string[];
  locationsOfCustomers?: string[];
  naicsCode?: string;
  businessDescription?: string;

  // account opener, control person, beneficial owners
  accountOpener?: PersonType;
  entityAccountOpenerIsControlPerson?: boolean;
  entityAccountOpenerIsBeneficialOwner?: boolean;
  controlPerson?: PersonType;
  entityControlPersonIsBeneficialOwner?: boolean;
  beneficialOwners?: PersonType[];

  // submit page
  submitted?: boolean;
}

export class PlatformRepository {
  static async get(id: string) {
    return client
      .get<GetPlatformRequest, PlatformType>(`/dashboard/platforms/${id}`, {
        sandbox: true,
        platformId: id,
      })
      .then((response) => convertValues<PlatformType>(response));
  }

  static async update(request: UpdatePlatformRequest) {
    return client
      .patch<UpdatePlatformRequest, PlatformType>(`/dashboard/platforms/${request.platformId}`, request, {
        platformId: request.platformId,
        sandbox: false,
      })
      .then((response) => convertValues<PlatformType>(response));
  }

  static async updateMeta(platformId: string, request: PlatformMeta) {
    const { depgraphNodeValues, ...requestPayload } = request;
    return client.patch<PlatformMeta, PlatformMeta>(`/dashboard/platforms/${platformId}/metadata`, requestPayload, {
      platformId,
      sandbox: false,
    });
  }

  static async getConfig(platformId: string) {
    return client.get<any, PlatformConfig>('/dashboard/platforms/config', {
      platformId,
    });
  }

  static async getMeta(platformId: string) {
    return client.get<any, PlatformMeta>(`/dashboard/platforms/${platformId}/metadata`, {
      platformId,
      sandbox: false,
    });
  }

  static async getInternationalWireConfig() {
    return client
      .get<unknown, InternationalWireBillingConfig>('/transfers/international-wire/config')
      .then((response) => convertValues<InternationalWireBillingConfig>(response));
  }

  static async updateInternationalWireConfig(request: InternationalWireBillingConfig) {
    return client
      .post<InternationalWireBillingConfig, InternationalWireBillingConfig>(
        '/transfers/international-wire/config',
        request,
        {
          allowNumbersInRequest: true,
        }
      )
      .then((response) => convertValues<InternationalWireBillingConfig>(response));
  }

  static async updateDependencyGraph(request: DepgraphNodeValues) {
    return client.patch<DepgraphNodeValues, DepgraphNodeValues>('/dashboard/onboarding/depgraph', request, {
      sandbox: false,
      allowNumbersInRequest: true,
    });
  }

  static async getDependencyGraph(platformId: string) {
    return client.get<unknown, DepgraphNodeValues>('/dashboard/onboarding/depgraph', {
      platformId,
      sandbox: false,
    });
  }
}
