import { gsap } from 'gsap';
import isEqual from 'lodash.isequal';
import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  ReactNode,
  useImperativeHandle,
  PropsWithChildren,
  useCallback,
} from 'react';
import styled from 'styled-components';

import { Chip, Dropdown, Fade, formatNumber, Icon } from '@column/column-ui-kit';

import { TransferAmount } from '~/app/pages/Transfers/Overview';
import { LogoLoading } from '~/elements';
import { TransferFilterType, useTransfers } from '~/hooks/useTransfers';
import { convertTransferAmount, TransferEntry, TransferListResponse } from '~/repositories/Transfer';
import { useSessionStore } from '~/stores/Session';
import { formatString } from '~/util';

import { Pagination } from './Pagination';
import { Table, TableColumn } from './Table';

// This is very WIP and working for transfers only currently - will become the base for any overview table in the future.
// The goal and improvements are:
// - use the new generalized API look
// - avoid uneccessary re-renders, improve performance
// - add consistent filter and pagination functionality using the URL query params as the state
// - many more improvements to come

// Going to outline and document this further once I got more time!
// So right now it's not generic at all and only works for transfers.

export interface OverviewTableProps {
  tableId: string;
  className?: string;
  fetchFilter?: TransferFilterType | null;
  onResponse?: (response?: TransferListResponse) => void;
  onLimitChange?: (limit: number) => void;
  columns: TableColumn[];
  action?: ReactNode;
  empty?: ReactNode;
  rowClick?: (row: any) => void;
}

export interface OverviewTableRefProps {
  fetch: (params?: TransferFilterType) => void;
  reset: () => void;
  setQueryParams: (object?: TransferFilterType) => void;
}

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  will-change: height;
  transform: translateZ(0);
`;

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const Entries = styled(Chip)`
  --chip-pale: ${({ theme }) => theme.secondary.blendToBackground(150)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 -4px;
  }
`;

const Bottom = styled.div`
  padding: 16px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OverviewTable = forwardRef<OverviewTableRefProps, PropsWithChildren<OverviewTableProps>>((props, ref) => {
  const { updateSettings } = useSessionStore();
  const { response, queryParams, isLoading, isInitialLoad, setQueryParams } = useTransfers({
    onSuccess: props.onResponse,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const fadeRef = useRef<HTMLDivElement>(null);

  const [entriesOpen, setEntriesOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.fetchFilter === null) {
      setQueryParams(
        {
          limit: queryParams?.limit,
          page: 1,
        },
        true
      );

      return;
    }

    if (!isInitialLoad && !isEqual(props.fetchFilter, queryParams)) {
      setQueryParams({
        ...props.fetchFilter,
        startingAfter: undefined,
        endingBefore: undefined,
        page: 1,
      });

      return;
    }

    setQueryParams(props.fetchFilter);
  }, [props.fetchFilter]);

  const handlePrev = useCallback(() => {
    setQueryParams({
      startingAfter: undefined,
      endingBefore: Number(queryParams?.page) - 1 === 1 ? undefined : response?.transfers[0].id,
      page: Number(queryParams?.page ?? 1) - 1,
    });
  }, [queryParams, response?.transfers]);

  const handleNext = useCallback(() => {
    setQueryParams({
      startingAfter:
        response?.transfers && Array.isArray(response?.transfers) ? response.transfers.at(-1)?.id : undefined,
      endingBefore: undefined,
      page: Number(queryParams?.page ?? 1) + 1,
    });
  }, [queryParams, response?.transfers]);

  useImperativeHandle(
    ref,
    () => ({
      setQueryParams,
      reset: () => {
        setQueryParams(
          {
            limit: queryParams?.limit,
            startingAfter: undefined,
            endingBefore: undefined,
            page: 1,
          },
          true
        );
      },
      fetch: (params?: TransferFilterType) => {
        setQueryParams({
          ...queryParams,
          ...params,
        });
      },
    }),
    [queryParams]
  );

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) =>
          !state.settings.tableSettings || !state.settings.tableSettings[props.tableId]
            ? 10
            : state.settings.tableSettings[props.tableId].limit,
        (limit) => {
          if (limit !== queryParams?.limit) {
            setQueryParams({
              limit,
            });
          }
        },
        {
          fireImmediately: true,
        }
      ),
    [queryParams]
  );

  const handleOnExit = () => {
    gsap.set(wrapperRef.current, {
      height: fadeRef.current?.offsetHeight,
    });
  };

  const handleOnEnter = () => {
    gsap.to(wrapperRef.current, {
      height: fadeRef.current?.offsetHeight,
      duration: 0.4,
      clearProps: 'height',
    });
  };

  const entryObject = useCallback(
    (entries: TransferListResponse['transfers']) => {
      return entries.map((entry: TransferEntry) => {
        const object: Partial<TransferEntry> = {};

        if (entry.type) {
          object.type =
            entry.type === 'ach_debit'
              ? 'ACH Debit'
              : entry.type === 'ach_credit'
                ? 'ACH Credit'
                : entry.type === 'check_debit' || entry.type === 'check_credit'
                  ? 'Check'
                  : entry.type === 'swift'
                    ? 'Intl. Wire'
                    : formatString(entry.type);
        }

        const convertAmount = convertTransferAmount(entry.type, entry.isIncoming);

        let { description } = entry;
        if ((entry.type === 'check_debit' || entry.type === 'check_credit') && entry.auxiliaryOnUs) {
          description = `#${entry.auxiliaryOnUs}${description ? ` ${description}` : ''}`;
        }

        return {
          id: entry.id,
          ...object,
          description: [
            <TransferAmount
              key={entry.id}
              $isAdding={
                entry.type === 'book'
                  ? props.fetchFilter?.bankAccountId
                    ? entry.senderInternalAccount.bankAccountId === props.fetchFilter.bankAccountId
                      ? false
                      : true
                    : undefined
                  : convertAmount !== -1
              }
            >
              {formatNumber(entry?.amount ?? 0)}
            </TransferAmount>,
            description,
          ],
          status: entry.status,
          incoming:
            entry.type === 'check_credit'
              ? 'Deposit'
              : entry.type === 'check_debit'
                ? 'Issue'
                : entry.type === 'book'
                  ? 'Internal'
                  : entry.isIncoming
                    ? 'Incoming'
                    : 'Outgoing',
          created: entry.createdAt,
        };
      });
    },
    [props.fetchFilter]
  );

  return (
    <Container>
      <Fade show={isInitialLoad} base={StyledLoading} />
      <Wrapper ref={wrapperRef}>
        <Fade show={!isInitialLoad} ref={fadeRef} onExit={handleOnExit} onEnter={handleOnEnter}>
          {props.empty && !isInitialLoad && (response?.transfers || []).length < 1 && !props.fetchFilter ? (
            props.empty
          ) : (
            <>
              {props.children}
              <Table
                className={props.className}
                action={props.action}
                onRowClick={props.rowClick}
                columns={props.columns}
                data={response?.transfers ? entryObject(response?.transfers) : []}
                isLoading={isLoading && !isInitialLoad}
              />
            </>
          )}
        </Fade>
      </Wrapper>
      <Bottom>
        <Fade show={!isInitialLoad && (response?.transfers || []).length >= 1} timeoutEnter={400}>
          <Dropdown
            options={[5, 10, 15, 20, 25, 50].map((entries: number) => ({
              label: entries.toString(),
              value: entries,
            }))}
            size="small"
            variant="muted"
            active={queryParams?.limit ?? 10}
            customLabel={
              <Entries counter={queryParams?.limit ?? 10}>
                {entriesOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
              </Entries>
            }
            onOpenChange={setEntriesOpen}
            onChange={(value: number) => {
              updateSettings({
                tableSettings: {
                  [props.tableId]: {
                    limit: value,
                  },
                },
              }).then(() => {
                if (props.onLimitChange) {
                  props.onLimitChange(value);
                }
                setQueryParams(
                  {
                    startingAfter: undefined,
                    endingBefore: undefined,
                    limit: value,
                    page: 1,
                  },
                  true
                );
              });
            }}
          />
        </Fade>
        <Fade show={!isInitialLoad && (response?.transfers || []).length >= 1} timeoutEnter={400}>
          <Pagination
            disablePrev={Number(queryParams?.page ?? 1) === 1}
            disableNext={!response?.hasMore}
            current={Number(queryParams?.page ?? 1)}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </Fade>
      </Bottom>
    </Container>
  );
});
