import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Dropdown, formatNumber, Icon } from '@column/column-ui-kit';

import { Datepicker, NotificationList } from '~/components';
import { useLazyBankAccounts } from '~/hooks';
import { useCustomizedBankAccountStatement } from '~/hooks/useReporting';
import { BankAccount } from '~/repositories/BankAccountRepository';
import { ModalType, useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { FormElement, FormLabel, Grid, Headline, Inner, ModalIconCircle, ModalText, Paragraph } from '~/styles';
import { CustomizedBankAccountStatementParams } from '~/typings/API';
import { downloadBlob } from '~/util';

import { ModalBase } from './Base';

const Wrapper = styled(ModalBase)`
  --modal-width: 244px;
  padding: 24px;
`;

const DatePreviewText = styled.span`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 4px;

    --icon-size: 14px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const DatePreview: React.FC<{ selectedDates: string[]; hoverDate?: string }> = ({ selectedDates, hoverDate }) => {
  if (selectedDates.length === 0) {
    return null;
  }

  const [fromDate, toDate] = useMemo(() => {
    if (selectedDates.length === 1) {
      if (hoverDate) {
        return [selectedDates[0], hoverDate].toSorted();
      }
      return [selectedDates[0], hoverDate ?? '?'];
    }

    return selectedDates.toSorted();
  }, [hoverDate, selectedDates]);

  return (
    <DatePreviewText>
      {fromDate}
      <Icon.ArrowRight />
      {toDate}
    </DatePreviewText>
  );
};

const getFilenameFromStatementRequest = (
  req: CustomizedBankAccountStatementParams | undefined,
  bankAccount: BankAccount | undefined
): string => {
  const nameParts = ['Custom Statement'];
  if (bankAccount) {
    if (bankAccount.displayName) {
      nameParts.push(bankAccount.displayName);
    } else if (bankAccount.description) {
      nameParts.push(bankAccount.description);
    }
  }

  if (req) {
    const { fromDate, toDate } = req;
    nameParts.push(`${fromDate} to ${toDate}`);
  }

  return `${nameParts.join(', ')}.csv`;
};

const StyledWrapper = styled(Wrapper)`
  --modal-width: 550px;
`;

const Circle = styled(ModalIconCircle)`
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;
  margin: 0 -24px -24px -24px;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 8px;
`;

export const ModalCreateStatement: React.FC = () => {
  const { closeModal } = useModalStore();
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const modalTypes: ModalType[] = ['CreateStatement'];

  const activeRequest = useRef<CustomizedBankAccountStatementParams | undefined>();
  const [bankAccount, setBankAccount] = useState<BankAccount | undefined>(undefined);
  const { response: bankAccounts, setQueryParams: searchBankAccounts } = useLazyBankAccounts();
  const [dates, setDates] = useState<string[]>([]);
  const [hoverDate, setHoverDate] = useState<string | undefined>(undefined);
  const [isCreateStatementLoading, setIsCreateStatementLoading] = useState<boolean>(false);
  const [isDownloadAnimationRunning, setIsDownloadAnimationRunning] = useState<boolean>(false);
  const [isBankAccountDropdownVisible, setIsBankAccountDropdownVisible] = useState<boolean>(false);

  const dropdownOptions = useMemo(() => {
    const selectedBankAccount = bankAccount ? [bankAccount] : [];
    const otherBankAccounts = bankAccounts?.bankAccounts.filter((acct) => acct.id !== bankAccount?.id) ?? [];
    const options = [...selectedBankAccount, ...otherBankAccounts];
    return (
      options.map((acct) => ({
        label: `${acct.displayName || acct.description}`,
        suffix: `${formatNumber(acct.balances.availableAmount)}`,
        small: acct.id,
        value: acct.id,
      })) ?? []
    );
  }, [bankAccount, bankAccounts]);

  const earliestSelectableDate = useMemo(() => {
    if (!bankAccount) {
      return new Date('2022-01-1');
    }
    const accountCreatedAt = new Date(bankAccount.createdAt);
    accountCreatedAt.setDate(accountCreatedAt.getDate() - 1);
    return accountCreatedAt;
  }, [bankAccount]);

  const customizedBankAccountStatement = useCustomizedBankAccountStatement({
    onSuccess: (data) => {
      const filename = getFilenameFromStatementRequest(activeRequest.current, bankAccount);
      downloadBlob(data, filename);
      addSuccessNotification({
        content: 'Statement created successfully',
      });
      activeRequest.current = undefined;
      setIsCreateStatementLoading(false);
      closeModal();
    },
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'popup',
      });
      activeRequest.current = undefined;
      setIsCreateStatementLoading(false);
    },
  });

  const createStatement = useCallback(() => {
    if (!bankAccount) {
      addDangerNotification({
        content: 'Please select an account.',
        display: 'popup',
      });

      return;
    }

    if (dates.length !== 2) {
      addDangerNotification({
        content: 'Please select a date range.',
        display: 'popup',
      });

      return;
    }

    const [fromDate, toDate] = dates.toSorted();

    const request: CustomizedBankAccountStatementParams = {
      bankAccountId: bankAccount.id,
      fromDate,
      toDate,
      type: 'csv',
    };
    activeRequest.current = request;
    setIsDownloadAnimationRunning(true);
    customizedBankAccountStatement.createRequest(request);
  }, [bankAccount, dates]);

  const handleSubmit = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      createStatement();
    },
    [createStatement]
  );

  const handleClose = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      closeModal();
    },
    [closeModal]
  );

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => ({ modalData: state.getModalData(), openModals: state.openModals }),
        ({ modalData, openModals }) => {
          if (openModals.includes('CreateStatement')) {
            setBankAccount(modalData?.bankAccount);
            setDates([]);
            setIsBankAccountDropdownVisible(!modalData?.bankAccount);
            if (!modalData?.bankAccount) {
              searchBankAccounts({});
            }
          }
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  const title = !isBankAccountDropdownVisible ? (bankAccount?.displayName ?? bankAccount?.description) : undefined;

  return (
    <StyledWrapper modalTypes={modalTypes}>
      <Circle>
        <Icon.AnimationDownload
          running={isDownloadAnimationRunning}
          onDone={() => setIsDownloadAnimationRunning(false)}
        />
      </Circle>
      <ModalText>
        <StyledHeadline fullWidth size="small">
          Custom CSV Statement
        </StyledHeadline>
        <div>
          <NotificationList display="popup" />
          <Paragraph>{`Create a statement ${title ? `for ${title}` : ''} for a date range or a single date. The statement will be downloaded as a .csv file.`}</Paragraph>
          <Grid>
            {isBankAccountDropdownVisible && (
              <FormElement fullWidth>
                <Inner pb={6}>
                  <FormLabel style={{ textAlign: 'left' }}>Account</FormLabel>
                </Inner>
                <Inner pb={0} pt={0}>
                  <Dropdown
                    options={dropdownOptions}
                    active={bankAccount?.id}
                    fullWidth
                    search
                    searchLabel="Search for description"
                    onSearchChange={(description: string) => searchBankAccounts({ description })}
                    onChange={(bankAccountId: string) => {
                      setBankAccount(bankAccounts?.bankAccounts.find((acct) => acct.id === bankAccountId));
                    }}
                  />
                </Inner>
              </FormElement>
            )}
            <FormElement fullWidth>
              <Inner pb={0} pt={isBankAccountDropdownVisible ? 0 : 24}>
                <Inner pb={12} pt={0} px={0}>
                  <FormLabel style={{ textAlign: 'left' }}>
                    Date Range <DatePreview hoverDate={hoverDate} selectedDates={dates} />
                  </FormLabel>
                </Inner>
                <Datepicker
                  date={dates}
                  onDateSubmit={(value) => {
                    setDates(value as string[]);
                  }}
                  showMonths={2}
                  disableBefore={earliestSelectableDate}
                  disableAfter={new Date()}
                  onHoverDate={(value) => setHoverDate(value)}
                />
              </Inner>
            </FormElement>
          </Grid>
        </div>
      </ModalText>
      <Buttons>
        <StyledButton variant="secondary" onClick={handleClose}>
          Cancel
        </StyledButton>
        <StyledButton
          variant={'primary'}
          onClick={handleSubmit}
          isDisabled={isCreateStatementLoading || !bankAccount || dates.length !== 2}
          isLoading={isCreateStatementLoading}
        >
          Create Statement
        </StyledButton>
      </Buttons>
    </StyledWrapper>
  );
};
