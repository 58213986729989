import { CreateCustomRoleRequestObject, CustomRolePermissionLevel } from '~/typings/API';

export enum Access {
  Default = 'default',
  NoAccess = 'no-access',
  ViewOnly = 'view-only',
  FullAccess = 'full-access',
  Custom = 'custom',
}

export type Permissions = { [permission: string]: CustomRolePermissionLevel };

export type TransfersAndAccountsFields = Pick<
  CreateCustomRoleRequestObject,
  'bankAccountLevelOverrides' | 'entityLevelOverrides' | 'platformLevelPermissions'
>;
