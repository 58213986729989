import isEqual from 'lodash.isequal';
import qs, { IStringifyBaseOptions } from 'qs';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type SetQueryCallback<T> = (prev: T) => T | undefined;

export const getQueryString = <T>(
  object: T | undefined,
  arrayFormat: IStringifyBaseOptions['arrayFormat'] = 'comma'
): string => {
  const query = qs.stringify(object, { encodeDotInKeys: false, arrayFormat });

  return query.length > 0 ? `?${query}` : '';
};

export const useQueryParams = <T>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getObject = useCallback(
    (object?: T) => {
      const params = object ? new URLSearchParams(object) : searchParams;

      const newQueryParamsObject: T = Array.from(params.keys()).reduce<T>((acc, key) => {
        acc[key as keyof T] = params.get(key)! as unknown as T[keyof T];
        return acc;
      }, {} as T);

      return newQueryParamsObject;
    },
    [searchParams]
  );

  const [queryParams, setQueryObjectState] = useState<T | undefined>(getObject());

  const getQueryObject = useMemo(() => getObject(), [getObject, searchParams]);

  const hasQueryParam = useCallback((key: keyof T): boolean => searchParams.has(String(key)), [searchParams]);

  const getQueryParam = useCallback(
    (key: keyof T): string | null => {
      return searchParams.get(String(key)) ?? null;
    },
    [searchParams]
  );

  const setQueryParam = useCallback(
    (key: keyof T, value: T[keyof T]) => {
      searchParams.set(String(key), String(value));

      setSearchParams(searchParams);
    },
    [searchParams]
  );

  const removeQueryParam = useCallback((key: keyof T) => {
    setSearchParams((prev) => {
      prev.delete(String(key));
      return prev;
    });
  }, []);

  const setQueryParams = useCallback(
    (callback: SetQueryCallback<T>, object?: T) => {
      const updatedParams = callback(getObject(object));
      const newSearchParams = new URLSearchParams();

      if (typeof updatedParams === 'undefined') {
        setSearchParams(undefined);
        return;
      }

      if (updatedParams) {
        for (const [key, value] of Object.entries(updatedParams)) {
          if (value !== null && value !== undefined && value !== '') {
            newSearchParams.set(key, String(value));
          } else {
            newSearchParams.delete(key);
          }
        }
      }

      setSearchParams(newSearchParams);
    },
    [searchParams]
  );

  useLayoutEffect(() => {
    const params = getObject();

    if (!isEqual(params, queryParams)) {
      setQueryObjectState(getObject());
    }
  }, [getObject]);

  return {
    hasQueryParam,
    getQueryParam,
    setQueryParam,
    removeQueryParam,
    queryParams,
    setQueryParams,
    getQueryObject,
  };
};
