export const getTimePassed = (time: string | Date): string => {
  const currentTime = new Date();
  const eventTime = new Date(time);
  const timeDiff = currentTime.getTime() - eventTime.getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 365) {
    const years = Math.floor(days / 365);
    return `${years}y ago`;
  }
  if (days > 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks}w ago`;
  }
  if (days > 0) {
    return `${days}d ago`;
  }
  if (hours > 0) {
    return `${hours}h ago`;
  }
  if (minutes > 0) {
    return `${minutes}m ago`;
  }
  return `${seconds}s ago`;
};
