import { DropdownOption } from '@column/column-ui-kit';

import { useSessionStore } from '~/stores/Session';
import { convertValues } from '~/util';

import { client } from './client';
import { uploadFile } from './util/upload';

export type DocumentsValueType =
  | 'identity_license'
  | 'identity_passport'
  | 'identity_utility'
  | 'dda_agreement'
  | 'bank_transaction_report'
  | 'bank_summary_report'
  | 'w9'
  | 'irs_letter'
  | 'other';

export const documentsDropdownOptions: DropdownOption[] = [
  {
    label: 'Identity license',
    value: 'identity_license',
  },
  {
    label: 'Identity passport',
    value: 'identity_passport',
  },
  {
    label: 'Identity utility',
    value: 'identity_utility',
  },
  {
    label: 'DDA Agreement',
    value: 'dda_agreement',
  },
  {
    label: 'W9',
    value: 'w9',
  },
  {
    label: 'IRS letter',
    value: 'irs_letter',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export interface DocumentsType {
  checksum: string;
  createdAt: string;
  updatedAt: string;
  description?: string;
  id: string;
  size: number;
  type: DocumentsValueType;
  url: string;
}

export interface DocumentsTypeEntry {
  description?: string;
  id: string;
  size: number;
  type: DocumentsValueType;
  ext: string;
}

export interface UploadDocumentRequest {
  description: string;
  type: DocumentsValueType;
  file: File;
}

export interface ListDocumentsResponse {
  documents: DocumentsTypeEntry[];
}

export const validDocumentExtensions = [
  '.pdf',
  '.json',
  '.tiff',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.txt',
  '.csv',
  '.html',
];

export class DocumentRepository {
  static async upload(request: UploadDocumentRequest, platformId: string) {
    return uploadFile<UploadDocumentRequest, DocumentsType>(`/dashboard/platforms/${platformId}/documents`, request, {
      platformId,
      isSandbox: false,
    }).then((response) => convertValues<DocumentsType>(response));
  }

  static async getAllPlatform(platformId: string) {
    return client
      .get<unknown, ListDocumentsResponse>(`/dashboard/platforms/${platformId}/documents`, { sandbox: false })
      .then((response) => ({
        documents: Array.isArray(response?.documents)
          ? response.documents.map((e) => convertValues<DocumentsTypeEntry>(e))
          : [],
      }));
  }

  static async getPlatform(id: string) {
    return client
      .get<
        any,
        DocumentsType
      >(`/dashboard/platforms/${useSessionStore.getState().currentPlatform?.id as string}/documents/${id}`, { sandbox: false })
      .then((response) => convertValues<DocumentsType>(response));
  }

  static async get(id: string) {
    return client
      .get<any, DocumentsType>(`/documents/${id}`)
      .then((response) => convertValues<DocumentsType>(response));
  }
}
