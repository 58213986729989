import React, { useCallback, useMemo, useState } from 'react';
import { FieldPath, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Chip, Fade, Icon, List, ListRow, ListRowVariant } from '@column/column-ui-kit';

import { Search } from '~/components/Search';
import { Box, Hint, Inner, Line, SmallHeadline } from '~/styles';
import {
  CustomRoleBankAccountLevelOverrides,
  CustomRoleEntityLevelOverrides,
  CustomRolePermissionLevel,
  CustomRolePlatformLevelPermissions,
} from '~/typings/API';

import { AccessDropdown } from './components/AccessDropdown';
import { CustomOverridesModal } from './components/CustomOverridesModal';
import { PermissionToggles } from './components/PermissionToggles';
import { Access, Permissions, TransfersAndAccountsFields } from './types';
import {
  BankAccountLevelPermissionsToLabelsMap,
  buildBankAccountOverridesFromPermissionLevel,
  buildBankAccountOverridesFromPermissions,
  buildEntityOverridesFromPermissionLevel,
  buildEntityOverridesFromPermissions,
  EntityLevelPermissionsToLabelsMap,
  getAccessLevel,
  getBankAccountPermissions,
  getEntityPermissions,
  PlatformLevelPermissionsToLabelsMap,
  setBankAccountPermissionsFromPermissionLevel,
  setBankAccountPermissionsFromPermissions,
  setEntityPermissionsFromPermissionLevel,
  setEntityPermissionsFromPermissions,
} from './utils';

const RowAction = styled.div`
  max-width: 200px;
`;

const RowTitle = styled.div`
  flex-grow: 1;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const TransfersAndAccounts: React.FC = () => {
  const { control, setValue } = useFormContext();

  const platformLevelPermissions = useWatch<TransfersAndAccountsFields>({
    name: 'platformLevelPermissions',
  }) as CustomRolePlatformLevelPermissions;
  const defaultAccess = useMemo(() => {
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'none'
      )
    ) {
      return Access.NoAccess;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'read'
      )
    ) {
      return Access.ViewOnly;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'write'
      )
    ) {
      return Access.FullAccess;
    }
    return Access.Custom;
  }, [platformLevelPermissions]);
  const showDefaultPermissions = defaultAccess !== Access.NoAccess;

  const setDefaultAccess = useCallback(
    (access: Access) => {
      let value: CustomRolePermissionLevel;
      switch (access) {
        case Access.FullAccess:
          value = 'write';
          break;
        case Access.ViewOnly:
          value = 'read';
          break;
        case Access.NoAccess:
          value = 'none';
          break;
        default:
          return;
      }
      Object.keys(PlatformLevelPermissionsToLabelsMap).forEach((permission) => {
        setValue(`platformLevelPermissions.${permission}` as FieldPath<TransfersAndAccountsFields>, value);
      });
    },
    [setValue]
  );

  return (
    <>
      <Inner>
        <Box variant={'secondary'}>
          <Inner>
            <Inner p={0} pb={showDefaultPermissions ? 24 : 0}>
              <RowWrapper>
                <RowTitle>
                  <SmallHeadline>Default Account Permissions</SmallHeadline>
                  <Hint>Set the default permissions for new and existing accounts</Hint>
                </RowTitle>
                <RowAction>
                  <AccessDropdown onChange={setDefaultAccess} value={defaultAccess} />
                </RowAction>
              </RowWrapper>
            </Inner>
            <Fade show={showDefaultPermissions}>
              <Line />
              <Inner p={0} pb={4} pt={28}>
                <PermissionToggles
                  control={control}
                  permissionsToLabelsMap={PlatformLevelPermissionsToLabelsMap}
                  fieldPathPrefix="platformLevelPermissions"
                />
              </Inner>
            </Fade>
          </Inner>
        </Box>
      </Inner>
      <Inner pt={0}>
        <RowWrapper>
          <RowTitle>
            <SmallHeadline>Account Overrides</SmallHeadline>
            <Hint>Override permissions on individual Accounts and Entities.</Hint>
          </RowTitle>
          <RowAction>
            <Search placeholder="Search bank accounts" />
          </RowAction>
        </RowWrapper>
      </Inner>
      <AccountOverridesList />
    </>
  );
};

const AccountActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 200px;
`;

const StyledFolderIcon = styled(Icon.Folder)`
  --icon-color: ${({ theme }) => theme.gray.blendToBackground(600)};
`;

const AccountOverridesList: React.FC = () => {
  const bankAccounts = {
    bankAccounts: [
      { id: 'bank-account-1', name: 'Bank Account 1', entityId: 'entity-1' },
      { id: 'bank-account-2', name: 'Bank Account 2', entityId: 'entity-1' },
      { id: 'bank-account-3', name: 'Bank Account 3', entityId: 'entity-2' },
    ],
    hasMore: false,
  };
  const entities = {
    entities: [
      { id: 'entity-1', name: 'Entity 1' },
      { id: 'entity-2', name: 'Entity 2' },
    ],
    hasMore: false,
  };

  const entityIdToBankAccountsMap = useMemo(() => {
    const map = new Map<string, { id: string; name: string }[]>();
    bankAccounts.bankAccounts.forEach((bankAccount) => {
      if (!map.has(bankAccount.entityId)) {
        map.set(bankAccount.entityId, []);
      }
      map.get(bankAccount.entityId)?.push(bankAccount);
    });
    return map;
  }, [bankAccounts, entities]);

  return (
    <List>
      <ListRow text="Entities and accounts" variant={ListRowVariant.Header} />
      {entities.entities.map((entity) => {
        return (
          <EntityListSection key={entity.id} entity={entity} bankAccounts={entityIdToBankAccountsMap.get(entity.id)} />
        );
      })}
    </List>
  );
};

interface EntityListSectionProps {
  bankAccounts?: { id: string; name: string }[];
  entity: { id: string; name: string };
}
const EntityListSection: React.FC<EntityListSectionProps> = ({ bankAccounts, entity }) => {
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false);
  const openOverridesModal = useCallback(() => setTimeout(() => setIsOverridesModalOpen(true), 150), []);
  const closeOverridesModal = useCallback(() => setIsOverridesModalOpen(false), []);
  const { setValue } = useFormContext<TransfersAndAccountsFields>();

  const platformLevelPermissions = useWatch<TransfersAndAccountsFields>({
    name: 'platformLevelPermissions',
  }) as CustomRolePlatformLevelPermissions;
  const { replace: replaceBankAccounts } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });
  const bankAccountLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'bankAccountLevelOverrides',
  }) as CustomRoleBankAccountLevelOverrides[];
  const { append: appendEntity, remove: removeEntity } = useFieldArray<TransfersAndAccountsFields>({
    name: 'entityLevelOverrides',
  });
  const entityLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'entityLevelOverrides',
  }) as CustomRoleEntityLevelOverrides[];

  const { entityIndex, entityPermissions, numOverrides } = useMemo(
    () =>
      getEntityPermissions({
        entityId: entity.id,
        entityLevelOverrides,
        platformPermissions: platformLevelPermissions,
      }),
    [entity.id, entityLevelOverrides, platformLevelPermissions]
  );

  const setOverridesFromPermissionLevel = useCallback(
    (permissionLevel: CustomRolePermissionLevel) => {
      if (entityIndex === -1) {
        appendEntity(buildEntityOverridesFromPermissionLevel(entity.id, permissionLevel));
      } else {
        setEntityPermissionsFromPermissionLevel({
          permissionLevel,
          keyPrefix: `entityLevelOverrides.${entityIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [entity.id, entityIndex, appendEntity, setValue]
  );

  const setOverridesFromPermissions = useCallback(
    (permissions: Permissions) => {
      if (entityIndex === -1) {
        appendEntity(buildEntityOverridesFromPermissions(entity.id, permissions));
      } else {
        setEntityPermissionsFromPermissions({
          permissions,
          keyPrefix: `entityLevelOverrides.${entityIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [entity.id, entityIndex, appendEntity, setValue]
  );

  // The current (persisted in the form) access level for this entity
  const entityAccessLevel = useMemo(() => {
    if (entityIndex === -1) {
      return Access.Default;
    }
    return getAccessLevel(entityPermissions);
  }, [entityIndex, entityPermissions]);
  // The access level to show in the UI
  const visibleAccessLevel = isOverridesModalOpen ? Access.Custom : entityAccessLevel;

  const setEntityAccessLevel = useCallback(
    (access: Access) => {
      switch (access) {
        case Access.Custom:
          openOverridesModal();
          return;
        case Access.Default:
          if (entityIndex !== -1) {
            removeEntity(entityIndex);
          }
          return;
        case Access.FullAccess:
          setOverridesFromPermissionLevel('write');
          return;
        case Access.ViewOnly:
          setOverridesFromPermissionLevel('read');
          return;
        case Access.NoAccess:
          setOverridesFromPermissionLevel('none');
          // Remove all bank account overrides for this entity
          replaceBankAccounts(
            bankAccountLevelOverrides.filter(
              (override) => !bankAccounts?.some((bankAccount) => bankAccount.id === override.bankAccountId)
            )
          );
          return;
      }
    },
    [bankAccountLevelOverrides, replaceBankAccounts, setOverridesFromPermissionLevel]
  );

  return (
    <>
      <CustomOverridesModal
        breadcrumbs={[entity.name]}
        currentPermissions={entityPermissions}
        onClose={closeOverridesModal}
        onSave={(permissions) => {
          setOverridesFromPermissions(permissions);
          closeOverridesModal();
        }}
        open={isOverridesModalOpen}
        parentPermissions={platformLevelPermissions as Permissions}
        permissionsToLabelsMap={EntityLevelPermissionsToLabelsMap}
      />
      <ListRow
        icon={<StyledFolderIcon filled flat />}
        rightDecoration={
          <>
            {entityAccessLevel === Access.Custom && numOverrides > 0 && <Chip>{numOverrides} Overrides</Chip>}
            <AccountActionsWrapper>
              <AccessDropdown onChange={setEntityAccessLevel} value={visibleAccessLevel} showDefault />
              {visibleAccessLevel === Access.Custom && (
                <Button onClick={openOverridesModal} variant={'secondary'} size={'small'}>
                  Edit
                </Button>
              )}
            </AccountActionsWrapper>
          </>
        }
        text={entity.name}
        variant={ListRowVariant.SubHeader}
      />
      {bankAccounts?.map((bankAccount) => (
        <BankAccountListRow
          key={bankAccount.id}
          bankAccount={bankAccount}
          parentName={entity.name}
          parentPermissions={entityPermissions}
        />
      ))}
    </>
  );
};

interface BankAccountListRowProps {
  bankAccount: { id: string; name: string };
  parentName: string;
  parentPermissions: Permissions;
}
const BankAccountListRow: React.FC<BankAccountListRowProps> = ({ bankAccount, parentName, parentPermissions }) => {
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState<boolean>(false);
  const openOverridesModal = useCallback(() => setTimeout(() => setIsOverridesModalOpen(true), 150), []);
  const closeOverridesModal = useCallback(() => setIsOverridesModalOpen(false), []);
  const { setValue } = useFormContext<TransfersAndAccountsFields>();

  const bankAccountLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'bankAccountLevelOverrides',
  }) as CustomRoleBankAccountLevelOverrides[];
  const { append: appendBankAccount, remove: removeBankAccount } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });

  const { bankAccountIndex, bankAccountPermissions, numOverrides } = useMemo(
    () =>
      getBankAccountPermissions({
        bankAccountId: bankAccount.id,
        bankAccountLevelOverrides,
        entityPermissions: parentPermissions,
      }),
    [bankAccount.id, bankAccountLevelOverrides, parentPermissions]
  );

  const setOverridesFromPermissionLevel = useCallback(
    (permissionLevel: CustomRolePermissionLevel) => {
      if (bankAccountIndex === -1) {
        appendBankAccount(buildBankAccountOverridesFromPermissionLevel(bankAccount.id, permissionLevel));
      } else {
        setBankAccountPermissionsFromPermissionLevel({
          permissionLevel,
          keyPrefix: `bankAccountLevelOverrides.${bankAccountIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [bankAccount.id, bankAccountIndex, appendBankAccount, setValue]
  );

  const setOverridesFromPermissions = useCallback(
    (permissions: Permissions) => {
      if (bankAccountIndex === -1) {
        appendBankAccount(buildBankAccountOverridesFromPermissions(bankAccount.id, permissions));
      } else {
        setBankAccountPermissionsFromPermissions({
          permissions,
          keyPrefix: `bankAccountLevelOverrides.${bankAccountIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [bankAccount.id, bankAccountIndex, appendBankAccount, setValue]
  );

  // The current (persisted in the form) access level for this bank account
  const bankAccountAccessLevel = useMemo(() => {
    if (bankAccountIndex === -1) {
      return Access.Default;
    }
    return getAccessLevel(bankAccountPermissions);
  }, [bankAccountIndex, bankAccountPermissions]);
  // The access level to show in the UI
  const visibleAccessLevel = isOverridesModalOpen ? Access.Custom : bankAccountAccessLevel;
  const parentAccessLevel = useMemo(() => getAccessLevel(parentPermissions), [parentPermissions]);

  const setBankAccountAccessLevel = useCallback(
    (access: Access) => {
      switch (access) {
        case Access.Custom:
          openOverridesModal();
          return;
        case Access.Default:
          if (bankAccountIndex !== -1) {
            removeBankAccount(bankAccountIndex);
          }
          return;
        case Access.FullAccess:
          setOverridesFromPermissionLevel('write');
          return;
        case Access.ViewOnly:
          setOverridesFromPermissionLevel('read');
          return;
        case Access.NoAccess:
          setOverridesFromPermissionLevel('none');
          return;
      }
    },
    [setOverridesFromPermissionLevel, removeBankAccount]
  );

  const showAccessDropdown = bankAccountAccessLevel !== Access.Default || parentAccessLevel !== Access.NoAccess;

  return (
    <>
      <CustomOverridesModal
        breadcrumbs={[parentName, bankAccount.name]}
        currentPermissions={bankAccountPermissions}
        onClose={closeOverridesModal}
        onSave={(permissions) => {
          setOverridesFromPermissions(permissions);
          closeOverridesModal();
        }}
        open={isOverridesModalOpen}
        parentPermissions={parentPermissions}
        permissionsToLabelsMap={BankAccountLevelPermissionsToLabelsMap}
      />
      <ListRow
        key={bankAccount.id}
        rightDecoration={
          showAccessDropdown && (
            <>
              {bankAccountAccessLevel === Access.Custom && numOverrides > 0 && <Chip>{numOverrides} Overrides</Chip>}
              <AccountActionsWrapper>
                <AccessDropdown onChange={setBankAccountAccessLevel} value={visibleAccessLevel} showDefault />
                {visibleAccessLevel === Access.Custom && (
                  <Button onClick={openOverridesModal} variant={'secondary'} size={'small'}>
                    Edit
                  </Button>
                )}
              </AccountActionsWrapper>
            </>
          )
        }
        text={bankAccount.name}
        variant={ListRowVariant.Default}
      />
    </>
  );
};
