import { PlatformRepository, InternationalWireBillingConfig } from '~/repositories/PlatformRepository';

import { createApiHook } from './utils/createApiHook';

export const useGetPlatformIntlWireConfig = createApiHook<InternationalWireBillingConfig, unknown>(
  PlatformRepository.getInternationalWireConfig,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
  }
);

export const useUpdatePlatformIntlWireConfig = createApiHook<
  InternationalWireBillingConfig,
  InternationalWireBillingConfig
>(PlatformRepository.updateInternationalWireConfig);
