import { gsap } from 'gsap';
import React, { FC, PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { helpSidebars } from '~/components/HelpSidebar';
import { modals } from '~/components/Modal';
import { MODAL_ROOT_CLASSNAME } from '~/components/ModalV2';
import { NotificationList, StyledNotification } from '~/components/NotificationList';
import { useScrollPosition } from '~/hooks';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useScrollStore } from '~/stores/Scroll';
import { useSessionStore } from '~/stores/Session';

import { Sidebar } from './Sidebar';
import { Events, PlatformStatus, User } from './Toolbar';

interface LocationState {
  from?: string;
}

const Wrapper = styled.div<{ $showSandboxBanner?: boolean }>`
  --sidebar-width: 248px;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto;
  padding-left: var(--sidebar-width);
`;

const Content = styled.div`
  --content-o: 0;
  opacity: var(--content-o);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Toolbar = styled.div<{ isScrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const Panel = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: auto;
`;

const FadeIn: React.FC<React.PropsWithChildren<{ show?: boolean }>> = ({ children, show }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (show !== undefined) {
      setIsVisible(show);
    }
    if (show === undefined) {
      setIsVisible(true);
    }
  }, [show]);
  return (
    <div
      style={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 200ms ease',
      }}
    >
      {children}
    </div>
  );
};

const PageFrame = styled.main`
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 0;
  overflow: auto;
  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  margin: 0 8px 8px 0;
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 50)},
    0 1px 2px rgba(0, 0, 0, 0.1);

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background: ${() => theme.background};
      box-shadow:
        0 0 0 1px ${() => theme.white.blendToBackground(1000, 80)},
        0 1px 2px rgba(0, 0, 0, 0.15);
    `};
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledNotificationList = styled(NotificationList)`
  position: fixed;
  bottom: 20px;
  left: calc(var(--sidebar-width) + 12px);
  padding: 0;
  width: 400px;
  margin: 0;

  ${StyledNotification} {
    margin: 8px 0 0 0;
  }
`;

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const setPageFrameScroll = useScrollStore((s) => s.setPageFrameScroll);
  const { openHelpSidebar, openHelpSidebars } = useHelpSidebarStore();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScrollPosition(wrapperRef);
  const { currentPlatform, currentUser, showSandboxBanner } = useSessionStore((s) => ({
    currentPlatform: s.currentPlatform,
    currentUser: s.currentUser,
    showSandboxBanner: s.showSandboxBanner,
  }));

  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as LocationState;

  useEffect(() => {
    setPageFrameScroll(scrollY);
  }, [scrollY]);

  useEffect(() => {
    if (state && state.from === 'register') {
      openHelpSidebar('Onboarding');
      navigate(location.pathname);
    }
    if (state && state.from === 'auth') {
      gsap.to(sidebarRef.current, {
        '--sidebar-o': 1,
        duration: 0.4,
        ease: 'none',
        onComplete: () => {
          navigate(location.pathname);
        },
      });
      gsap.to(contentRef.current, {
        '--content-o': 1,
        duration: 0.4,
        ease: 'none',
      });
    } else {
      gsap.set(sidebarRef.current, {
        '--sidebar-o': 1,
      });
      gsap.set(contentRef.current, {
        '--content-o': 1,
      });
    }
  }, [location]);

  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    wrapper.scrollTo({ top: 0 });
  }, [wrapperRef, location]);

  return (
    <Wrapper id="scroll-container" $showSandboxBanner={showSandboxBanner}>
      <Sidebar ref={sidebarRef} />
      <Content ref={contentRef}>
        <Toolbar ref={toolbarRef} isScrolled={scrollY > 0 && openHelpSidebars.length < 1}>
          <Panel>
            {currentPlatform && (
              <FadeIn>
                <Events />
              </FadeIn>
            )}
            {currentPlatform && (
              <FadeIn>
                <PlatformStatus />
              </FadeIn>
            )}

            <FadeIn show={!!currentUser}>
              <User />
            </FadeIn>
          </Panel>
        </Toolbar>
        <PageFrame ref={wrapperRef}>
          <Inner>{children}</Inner>
        </PageFrame>
        <StyledNotificationList display="global" size="large" />
      </Content>

      <div className={MODAL_ROOT_CLASSNAME} />

      {currentPlatform && modals}
      {currentPlatform && helpSidebars}
    </Wrapper>
  );
};
