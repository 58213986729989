import React from 'react';

import { Dropdown } from '@column/column-ui-kit';

import { RenderFields } from '~/components';
import { CreateCustomRoleRequestObject } from '~/typings/API';

type AdditionalPermissionsFields = Pick<CreateCustomRoleRequestObject, 'platformLevelPermissions'>;

const OptionCreate = { label: 'Create', value: 'write' };
const OptionCreateAndEdit = { label: 'Create & Edit', value: 'write' };
const OptionEdit = { label: 'Edit Access', value: 'write' };
const OptionNoAccess = { label: 'No Access', value: 'none' };
const OptionViewOnly = { label: 'View Only', value: 'read' };

const OptionsNotCreatable = [OptionNoAccess, OptionViewOnly, OptionEdit];
const OptionsNotEditable = [OptionNoAccess, OptionViewOnly, OptionCreate];
const OptionsFull = [OptionNoAccess, OptionViewOnly, OptionCreateAndEdit];

export const AdditionalPermissions: React.FC = () => {
  return RenderFields<AdditionalPermissionsFields>({
    sections: [
      {
        fields: [
          {
            id: 'platformLevelPermissions.platformInfo',
            label: 'Platform Settings',
            description: 'View and edit platform information and due diligence.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsNotCreatable} onChange={onChange} fullWidth />
            ),
          },
        ],
      },
      {
        fields: [
          {
            id: 'platformLevelPermissions.entities',
            label: 'Entities',
            description: 'Set the default permissions for new and existing entities.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.counterparties',
            label: 'Counterparties',
            description: 'Set the default permissions for new and existing counterparties.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsNotEditable} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.apiKeys',
            label: 'API Keys',
            description:
              'API keys allow developers on your team to connect to your platform. Access should be granted sparingly.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.loans',
            label: 'Loans',
            description: 'Set the default loan permissions for new and existing bank accounts.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
        ],
      },
      {
        fields: [
          {
            id: 'platformLevelPermissions.bankAccounts',
            label: 'Create Bank Accounts',
            description: 'Set if new bank accounts can be created by this role.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.reporting',
            label: 'Reports and Statements',
            description: 'Set the default reporting permissions for new and existing bank accounts.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
        ],
      },
    ],
  });
};
