import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { Permissions } from '../types';
import { Breadcrumb, Modal, ModalSize, ModalVariant } from '~/components';

import { PermissionToggles } from './PermissionToggles';

const ClearOverridesButton = styled(Button)`
  padding: 0;
`;

const CustomOverridesModalControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`;

interface CustomOverridesModalProps {
  breadcrumbs: string[];
  open: boolean;
  onClose: () => void;
  onSave: (permissions: Permissions) => void;
  currentPermissions: Permissions;
  parentPermissions: Permissions;
  permissionsToLabelsMap: { [permission: string]: string };
}
export const CustomOverridesModal: React.FC<CustomOverridesModalProps> = ({
  breadcrumbs,
  currentPermissions,
  open,
  onClose,
  onSave,
  parentPermissions,
  permissionsToLabelsMap,
}) => {
  const effectivePermissions = useMemo(() => {
    return Object.keys(permissionsToLabelsMap).reduce(
      (o, permission) => ({
        ...o,
        [permission]:
          currentPermissions[permission] !== 'none' ? currentPermissions[permission] : parentPermissions[permission],
      }),
      {}
    );
  }, [currentPermissions, parentPermissions]);
  const { control, getValues, reset, setValue } = useForm<Permissions>({
    values: effectivePermissions,
  });
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const resetOverrides = useCallback(() => {
    Object.keys(permissionsToLabelsMap).forEach((permission) => {
      setValue(permission, parentPermissions[permission]);
    });
  }, [setValue, parentPermissions]);

  return (
    <Modal
      headline="Custom overrides"
      open={open}
      onClose={onClose}
      primaryButton={{
        text: 'Save Changes',
        onClick: () => onSave(getValues()),
      }}
      secondaryButton={{ text: 'Cancel', onClick: onClose }}
      size={ModalSize.FitContent}
      variant={ModalVariant.Compact}
    >
      <CustomOverridesModalControls>
        <Breadcrumb entries={breadcrumbs.map((breadcrumb) => ({ label: breadcrumb }))} />
        <ClearOverridesButton
          icon={<Icon.Reset />}
          iconRight
          size={'small'}
          variant="subtle-primary"
          onClick={() => resetOverrides()}
        >
          Clear overrides
        </ClearOverridesButton>
      </CustomOverridesModalControls>
      <PermissionToggles control={control} permissionsToLabelsMap={permissionsToLabelsMap} />
    </Modal>
  );
};
