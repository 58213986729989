import React from 'react';
import styled, { css } from 'styled-components';

import { Button, Toggle } from '@column/column-ui-kit';

import { PopoverBase, PopoverBaseProps } from '../Base';

interface ListEntry {
  label: string;
  id: string;
}

interface PopoverListProps extends PopoverBaseProps {
  options: ListEntry[];
  active?: string[] | boolean;
  onDone?: () => void;
  onReset?: () => void;
  onActiveChange?: (active: string[] | boolean) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-padding: 12px 0;
  --popover-width: 216px;
`;

const List = styled.div`
  display: grid;
  grid-gap: 12px;
`;

export const PopoverListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
`;

export const PopoverListLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.background};
`;

export const PopoverAction = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-radius: 0 0 12px 12px;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  padding: 12px 12px 11px 12px;
  margin: 12px 0 -11px 0;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;

export const PopoverList: React.FC<PopoverListProps> = (props) => {
  const isActive = (current: string[] | boolean | undefined, value: string) => {
    return current === true || (Array.isArray(current) && current.includes(value));
  };

  const handleToggleChange = (value: string, bool: boolean) => {
    if (props.onActiveChange) {
      let active = props.active;

      if (typeof active === 'boolean' || typeof active === 'undefined') {
        active = active
          ? props.options.map((entry: ListEntry) => {
              return entry.id;
            })
          : [];
      }

      if (Array.isArray(active)) {
        if (bool) {
          active = [...active, value];
        } else if (!bool && active.includes(value)) {
          active = active.filter((e) => e !== value);
        }
      }

      props.onActiveChange(active);
    }
  };

  const handleResetClick = () => {
    if (props.onReset) {
      props.onReset();
    }
  };

  const handleDoneClick = () => {
    if (props.onDone) {
      props.onDone();
    }
  };

  return (
    <Wrapper {...props}>
      <List>
        {props.options.map((entry: ListEntry, index: number) => (
          <PopoverListTitle key={index}>
            <PopoverListLabel>{entry.label}</PopoverListLabel>
            <Toggle
              isChecked={isActive(props.active, entry.id)}
              onCheckedChange={(value: boolean) => handleToggleChange(entry.id, value)}
            />
          </PopoverListTitle>
        ))}
      </List>
      <PopoverAction>
        <Button onClick={handleResetClick} size="small" variant="secondary">
          Reset
        </Button>
        <Button onClick={handleDoneClick} size="small">
          Done
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
