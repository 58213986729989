import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  Path,
  PathValue,
  FieldValues,
  useFormContext,
  ControllerProps,
} from 'react-hook-form';
import styled, { css } from 'styled-components';

import { Icon, Tooltip } from '@column/column-ui-kit';

import { useHelpSidebarStore, HelpSidebarCustomType } from '~/stores/HelpSidebar';

export type FormFieldProps<T extends FieldValues> = {
  id: Path<T>;
  label?: string;
  description?: string;
  tooltip?: string | HelpSidebarCustomType;
  rules?: ControllerProps<T, Path<T>>['rules'];
  defaultValue?: PathValue<T, Path<T>> | undefined;
  children: (field: ControllerRenderProps<T, Path<T>>, fieldState: ControllerFieldState) => ReactElement | ReactNode;
  updateOn?: Path<T>;
  oneColumn?: boolean;
  noStyle?: boolean;
};

const Label = styled.div`
  margin-top: 12px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
`;

const ControllerContainer = styled.div`
  isolation: isolate;
  z-index: 1;
`;

const Wrapper = styled.div<{ $oneColumn?: boolean; $noStyle?: boolean }>`
  ${({ $noStyle }) =>
    !$noStyle &&
    css`
      display: grid;
      grid-gap: 32px;
      align-items: start;
      grid-template-columns: 25% auto 25%;
    `};

  ${({ $oneColumn }) =>
    $oneColumn &&
    css`
      grid-gap: 8px;
      grid-template-columns: auto;

      ${Label} {
        margin-top: 0;
      }
    `}
`;

const Title = styled.div`
  line-height: 1;
  display: grid;
  grid-gap: 4px;
`;

const LabelTooltip = styled(Tooltip)`
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(500)};
    --icon-size: 16px;
  }

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(900)};
    }
  }
`;
const TooltipButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
`;

const LabelSidebarTooltip = styled(Tooltip)`
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 1.5px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.secondary.blendToForeground(1, 900)};
    opacity: 0;
    transition: opacity 0.1s;
    z-index: 0;
  }

  svg {
    --icon-size: 16px;
    transition: opacity 0.2s;
    z-index: 1;

    &:nth-child(1) {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(500)};
    }

    &:nth-child(2) {
      --icon-color: ${({ theme }) => theme.background};
      position: absolute;
      top: 0;
      pointer-events: none;
      transform: translateX(4px);
      opacity: 0;
      transition:
        transform 0.1s,
        opacity 0.1s;
      border-radius: 100%;
    }
  }

  &:hover {
    cursor: pointer;

    &:after {
      opacity: 1;
      transition: opacity 0.2s;
    }

    svg {
      &:nth-child(1) {
        opacity: 0;
        transition: opacity 0.1s;
      }

      &:nth-child(2) {
        transform: translateX(0);
        opacity: 1;
        transition:
          transform 0.2s,
          opacity 0.2s;
      }
    }
  }
`;

const Asterix = styled.span`
  color: ${({ theme }) => theme.danger.background};
  font-weight: 700;
  display: inline-block;
  margin-left: 2px;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  max-width: 32ch;
`;

export const FormField = <T extends FieldValues>({
  id,
  label,
  rules,
  defaultValue,
  children,
  description,
  tooltip,
  updateOn,
  oneColumn,
  noStyle,
}: FormFieldProps<T>) => {
  const { control, watch } = useFormContext<T>();
  const openHelpSidebarCustom = useHelpSidebarStore((s) => s.openHelpSidebarCustom);

  const watchValue = updateOn ? watch(updateOn) : undefined;

  const handleTooltipClick = useCallback(() => {
    if (typeof tooltip === 'string' || typeof tooltip === 'undefined') {
      return;
    }

    openHelpSidebarCustom(tooltip);
  }, [tooltip]);

  return (
    <Wrapper $oneColumn={oneColumn} $noStyle={noStyle} className="form-field">
      {!noStyle && (
        <Title>
          <Label>
            {label}
            {!!rules?.required && <Asterix>*</Asterix>}

            {typeof tooltip === 'string' ? (
              <LabelTooltip content={tooltip} zIndex={14}>
                <Icon.CircleQuestionmark />
              </LabelTooltip>
            ) : (
              typeof tooltip === 'object' && (
                <TooltipButton type="button" onClick={handleTooltipClick}>
                  <LabelSidebarTooltip content="Click to learn more">
                    <Icon.CircleQuestionmark />
                    <Icon.ChevronLeft />
                  </LabelSidebarTooltip>
                </TooltipButton>
              )
            )}
          </Label>
          {description && <Description>{description}</Description>}
        </Title>
      )}
      <ControllerContainer>
        <Controller
          key={watchValue}
          name={id}
          control={control}
          defaultValue={defaultValue}
          rules={{
            ...rules,
            required: rules?.required === true ? `Please fill out the ${label} field` : undefined,
          }}
          render={({ field, fieldState }) => {
            return children(field, fieldState) as ReactElement;
          }}
        />
      </ControllerContainer>
    </Wrapper>
  );
};
