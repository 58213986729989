import { APIKEYS } from './ApiKeys';
import { PageFeatures } from './Features';
import { INFO } from './Info';
import { PageCreateRole } from './Roles/Create';
import { PageRootEntity } from './RootEntity';
import { WEBHOOKS } from './Webhooks';

export const PLATFORM = {
  Features: PageFeatures,
  RootEntity: PageRootEntity,
  CreateRole: PageCreateRole,
  ...INFO,
  ...APIKEYS,
  ...WEBHOOKS,
} as const;
