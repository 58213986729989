import isEqual from 'lodash.isequal';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { useQueryParams } from '../useQueryParams';
import { useSessionStore } from '~/stores/Session';
import { ApiError } from '~/typings/API';

interface FetchProps<Response, Request> {
  onPlatformChange: (data: Response) => void;
  onSuccess: (data: Response) => void;
  onError: (error: ApiError) => void;
  onInitialLoad: (data: Response) => void;
  initialQueryParams: Request;
  initialParams: Request;
  shouldNotLoad: boolean;
}

export type RepositoryMethod<Response, Request> = (params: Request) => Promise<Response>;

interface HookOptions<Request> {
  triggerAutomatically: boolean;
  triggerOnSessionStoreSubscribe: boolean;
  includeQueryParams: boolean;
  addQueryParamsToUrl: boolean;
  excludeQueryParams: (keyof Request)[];
}

type TriggerSource = 'session' | 'automatic' | 'query' | 'manual';

export const createApiHook =
  <Response, Request>(method: RepositoryMethod<Response, Request>, options: Partial<HookOptions<Request>> = {}) =>
  ({
    onSuccess,
    onError,
    onInitialLoad,
    onPlatformChange,
    initialQueryParams,
    initialParams,
    shouldNotLoad,
  }: Partial<FetchProps<Response, Request>> = {}) => {
    const {
      triggerAutomatically,
      triggerOnSessionStoreSubscribe,
      includeQueryParams,
      addQueryParamsToUrl,
      excludeQueryParams,
    } = options;
    const [stateChangeTrigger, setStateChangeTrigger] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [response, setResponse] = useState<Response | undefined>(undefined);
    const queryParamsRef = useRef<Request | undefined>(initialQueryParams);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [triggerSource, setTriggerSource] = useState<TriggerSource>('manual');

    const {
      queryParams: urlQueryParams,
      setQueryParams: setUrlQueryParams,
      setQueryParam: setUrlQueryParam,
    } = useQueryParams<Request | undefined>();

    const [queryParams, setLocalQueryParams] = useState<Request | undefined>(initialQueryParams);

    const setQueryParams = useCallback(
      (newParams: Request | undefined, overwrite?: boolean) => {
        if (overwrite) {
          setLocalQueryParams(() => newParams);

          return;
        }

        if (!isEqual(queryParams, newParams)) {
          setLocalQueryParams((prev) => (!prev ? newParams : { ...prev, ...newParams }));
        }
      },
      [setLocalQueryParams, queryParams]
    );

    const isInitialLoad = useMemo(() => isLoading && initialLoad, [isLoading, initialLoad]);

    const createRequest = useCallback(
      async (params?: Request): Promise<Response | void> => {
        if (shouldNotLoad) {
          return;
        }

        setIsLoading(true);

        try {
          const parameters = (
            includeQueryParams || addQueryParamsToUrl
              ? { ...params, ...queryParams }
              : initialParams
                ? { ...initialParams, ...params }
                : params
          ) as Request;

          if (excludeQueryParams && excludeQueryParams?.length > 0) {
            excludeQueryParams.forEach((key) => {
              delete parameters[key as keyof Request];
            });
          }

          const result = await method(parameters);
          setResponse(result);

          if (onSuccess) {
            onSuccess(result);
          }

          if (initialLoad && onInitialLoad) {
            onInitialLoad(result);
          }

          if (initialLoad) {
            setInitialLoad(false);
          }

          return result;
        } catch (error) {
          if (error === 'Unauthorized') {
            return;
          }

          if (onError) {
            onError(error as ApiError);
          }

          throw error;
        } finally {
          setIsLoading(false);
        }
      },
      [
        initialLoad,
        onSuccess,
        onError,
        onInitialLoad,
        initialParams,
        includeQueryParams,
        addQueryParamsToUrl,
        method,
        shouldNotLoad,
        queryParams,
      ]
    );

    useEffect(() => {
      if (stateChangeTrigger > 0) {
        createRequest().then((data) => {
          if (data && triggerSource === 'session' && onPlatformChange) {
            onPlatformChange(data);
          }
        });
      }
    }, [stateChangeTrigger]);

    useEffect(() => {
      if (addQueryParamsToUrl) {
        setUrlQueryParams(() => queryParams);
      }
    }, [queryParams, addQueryParamsToUrl]);

    useEffect(
      () =>
        useSessionStore.subscribe(
          (state) => [state.isLoading, state.isSignedIn(), state.currentUser?.defaultPlatformId, state.isSandbox],
          (state) => {
            const isLoadingState = state[0] as boolean | undefined;
            const isSignedIn = state[1] as boolean | undefined;
            const defaultPlatformId = state[2] as string | undefined;

            if (isLoadingState || !isSignedIn || !triggerOnSessionStoreSubscribe) {
              return;
            }

            if (defaultPlatformId) {
              setTriggerSource('session');

              setStateChangeTrigger((prevState) => prevState + 1);
            }
          },
          {
            fireImmediately: triggerAutomatically,
            equalityFn: shallow,
          }
        ),
      [triggerOnSessionStoreSubscribe, triggerAutomatically]
    );

    useEffect(() => {
      if (triggerAutomatically && !triggerOnSessionStoreSubscribe) {
        setTriggerSource('automatic');

        setStateChangeTrigger((prev) => prev + 1);
      }
    }, [triggerAutomatically, triggerOnSessionStoreSubscribe]);

    useEffect(() => {
      if (includeQueryParams && queryParamsRef.current !== queryParams) {
        setTriggerSource('query');
        setStateChangeTrigger((prev) => prev + 1);

        queryParamsRef.current = queryParams;
      }
    }, [queryParams, includeQueryParams, queryParamsRef.current]);

    return {
      createRequest,
      response,
      isLoading,
      isInitialLoad,
      queryParams,
      setQueryParams,
      setUrlQueryParams,
      setUrlQueryParam,
    };
  };
