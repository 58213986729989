import { DropdownOption } from '@column/column-ui-kit';

import { getDateFormat } from './getDateFormat';

type DateRangeOutput = {
  dateString: string;
  time?: number;
  timeAM?: boolean;
};

export const convertRangeToDate = (dateAtGte?: string, dateAtLt?: string): DateRangeOutput => {
  if (!dateAtGte) {
    return { dateString: '' };
  }

  const dateRegex = /(\d{4}-\d{2}-\d{2})T(\d{2}):(\d{2})/;
  const gteMatch = dateAtGte.match(dateRegex);
  const ltMatch = dateAtLt?.match(dateRegex);

  if (!gteMatch) {
    return { dateString: '' };
  }

  const dateString = gteMatch[1];
  const hour = parseInt(gteMatch[2], 10);
  const minute = parseInt(gteMatch[3], 10);

  if (!ltMatch || (ltMatch && ltMatch[1] !== dateString)) {
    return { dateString };
  }

  const time = hour * 100 + minute;
  const timeAM = hour < 12 || hour === 24;

  return {
    dateString,
    time,
    timeAM,
  };
};

const customPadStart = (value: number, digits: number) => {
  const val = Math.abs(value);
  const zeros = Math.max(0, digits - Math.floor(val).toString().length);

  let zeroString = Math.pow(10, zeros).toString().substr(1);

  if (value < 0) {
    zeroString = `-${zeroString}`;
  }

  return zeroString + val;
};

const getHoursMinutesOffset = () => {
  const offset = new Date().getTimezoneOffset() * -1;

  const hours = Math.floor(offset / 60);
  const minutes = Math.round((offset / 60 - hours) * 60);

  return `${hours >= 0 ? '+' : ''}${customPadStart(hours, 2)}:${customPadStart(minutes, 2)}`;
};

export const convertDateToRange = (dateString?: string, time?: string, timeAM?: boolean) => {
  if (!dateString) {
    return {};
  }

  const convertedTime = time?.replace(':', '');

  if (typeof convertedTime === 'undefined' || convertedTime.length !== 4) {
    const nextDayObj = new Date(`${dateString}T00:00:00Z`);

    nextDayObj.setTime(nextDayObj.getTime() + nextDayObj.getTimezoneOffset() * 60 * 1000);
    nextDayObj.setDate(nextDayObj.getDate() + 1);

    return {
      createdAtGte: `${dateString}T00:00:00${getHoursMinutesOffset()}`,
      createdAtLt: `${getDateFormat(nextDayObj)}T00:00:00${getHoursMinutesOffset()}`,
    };
  }

  let hours = parseInt(convertedTime.substring(0, 2), 10);
  const minutes = parseInt(convertedTime.substring(2, 4), 10);

  if (!timeAM && hours < 12) {
    hours += 12;
  } else if (timeAM && hours === 12) {
    hours = 0;
  }

  const date = new Date(`${dateString}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00Z`);

  const createdAtGte = new Date(date.getTime() - 60000 * 2);
  const createdAtLt = new Date(date.getTime() + 60000 * 2);

  return {
    createdAtGte: `${createdAtGte.toISOString().slice(0, -1)}${getHoursMinutesOffset()}`,
    createdAtLt: `${createdAtLt.toISOString().slice(0, -1)}${getHoursMinutesOffset()}`,
  };
};

export const convertDatesToRange = (
  startDateString?: string,
  startTime?: string,
  startTimeAM?: boolean,
  endDateString?: string,
  endTime?: string,
  endTimeAM?: boolean
) => {
  const createTimeObject = (dateString: string, time?: string, timeAM?: boolean) => {
    if (!time) {
      const dateObj = new Date(`${dateString}T00:00:00Z`);
      dateObj.setTime(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000);
      return dateObj;
    }

    const convertedTime = time.replace(':', '');
    let hours = parseInt(convertedTime.substring(0, 2), 10);
    const minutes = parseInt(convertedTime.substring(2, 4), 10);

    if (!timeAM && hours < 12) {
      hours += 12;
    } else if (timeAM && hours === 12) {
      hours = 0;
    }

    return new Date(`${dateString}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00Z`);
  };

  if (!startDateString || !endDateString) {
    return {};
  }

  const startDateTime = createTimeObject(startDateString, startTime, startTimeAM);
  const endDateTime = createTimeObject(endDateString, endTime, endTimeAM);

  return {
    createdAtGte: `${startDateTime.toISOString().slice(0, -1)}${getHoursMinutesOffset()}`,
    createdAtLt: `${endDateTime.toISOString().slice(0, -1)}${getHoursMinutesOffset()}`,
  };
};

export const getTransferDateFullFilter = (createdAt?: string, createdAtTime?: string, createdAtTimeAM?: boolean) => {
  if (createdAt && createdAt.length > 0) {
    return {
      ...convertDateToRange(createdAt, createdAtTime, createdAtTimeAM),
      createdAt,
      createdAtTime,
      createdAtTimeAM: createdAtTimeAM ?? true,
    };
  } else {
    return {
      createdAt: undefined,
      createdAtTime: undefined,
      createdAtTimeAM: undefined,
      createdAtGte: undefined,
      createdAtLt: undefined,
    };
  }
};

export const parseDateTime = (dateTime: string, substractDay?: boolean) => {
  const dt = new Date(dateTime);

  if (substractDay) {
    dt.setDate(dt.getDate() - 1);
  }

  const dateString = dt
    .toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
  const hour = dt.getHours();
  const minute = dt.getMinutes().toString().padStart(2, '0');
  return {
    date: dateString,
    time: `${customPadStart(hour % 12 === 0 ? 12 : hour % 12, 2)}:${minute}`,
    timeAM: hour < 12,
  };
};

export type MonthBoundariesType = {
  fromDate: string;
  toDate: string;
};

export const getMonthBoundaries = (year: number, month: number): MonthBoundariesType => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);

  const fromDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate
    .getDate()
    .toString()
    .padStart(2, '0')}`;
  const toDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate
    .getDate()
    .toString()
    .padStart(2, '0')}`;

  return { fromDate, toDate };
};

export type ParseMounthBoundariesType = {
  year?: number;
  month?: number;
};

export const parseMounthBoundaries = (fromDate?: string): ParseMounthBoundariesType => {
  if (!fromDate) {
    return {
      year: undefined,
      month: undefined,
    };
  }

  const dateParts = fromDate.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;

  return { year, month };
};

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MonthsMap = new Map<number, string>(
  Months.map((month, index) => {
    return [index, month];
  })
);

export const getYearOptions = (startYear: number): DropdownOption[] => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({
      label: `${year}`,
      value: year,
    });
  }

  return years.reverse();
};
