import React, { FormEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  AmountInput,
  Button,
  Chip,
  CurrenciesUSD,
  Dropdown,
  DropdownOption,
  Fade,
  formatNumber,
  Icon,
  Input,
  NumberInput,
} from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader, SectionHeader, Table, TableColumn } from '~/components';
import { Loading, LogoLoading, RelativeTime } from '~/elements';
import { useGetPlatformIntlWireConfig, useUpdatePlatformIntlWireConfig } from '~/hooks';
import { useAccountNumber, useBankAccount, useBankAccounts } from '~/hooks/useBankAccounts';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { PlatformType } from '~/models';
import {
  DashboardUserRedacted,
  FactorTypeResponse,
  InviteRepository,
  PlatformRoleName,
  userGroups,
  userGroupsDropdown,
  UserRepository,
} from '~/repositories';
import { InternationalWireBillingConfig, PlatformRepository, UpdatePlatform } from '~/repositories/PlatformRepository';
import { Factor } from '~/repositories/UserRepository';
import { useAlertStore } from '~/stores/Alert';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import {
  FormElement,
  FormElementAction,
  FormLabel,
  FormLabelTooltip,
  FormText,
  Grid,
  Inner,
  Line,
  Truncate,
} from '~/styles';
import { formatString, getDateLongUTC } from '~/util';

interface Params {
  id?: string;
}

interface InviteList {
  id?: string;
  email?: string;
  created?: Date | undefined;
  role?: string;
  action?: ReactNode;
}

interface UserListInfo {
  id: string;
  name: string;
  isOwner: boolean;
}

interface UserListRole {
  id: string;
  email: string;
  name: string;
}

interface UserList {
  id?: string;
  info?: UserListInfo;
  email?: string;
  factors?: Factor[];
  role?: UserListRole;
  action?: ReactNode;
}

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const StyledLoading = styled(Loading)`
  top: 40px;
  transform: translateX(-50%);
`;

const Action = styled.div`
  display: table;
  align-self: end;
  justify-self: start;
`;

const Relative = styled.div`
  position: relative;
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
`;

const UserInfo = styled.div`
  display: inline-flex;
  gap: 4px;
  vertical-align: top;
  margin-left: 8px;
`;

const StyledTruncate = styled(Truncate)`
  display: flex;
  align-items: center;
`;

const SaveToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PrimaryColumn = styled.div`
  border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const StyledAmountInput = styled(AmountInput)`
  > div:first-child {
    gap: 2px;
  }
`;

const StyledTable = styled(Table)`
  tr {
    align-items: center;
  }
`;

export const PagePlatformsEdit: React.FC = () => {
  const deviceMFAEnabled = useFeatureFlag(FeatureFlag.EnableDeviceMFA);
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const { response: bankAccounts, setQueryParams: searchBankAccounts } = useBankAccounts();
  const { response: bankAccountActive, createRequest: getBankAccountActive } = useBankAccount();
  const { createRequest: getAccountNumberActive } = useAccountNumber({
    onSuccess: (d) => getBankAccountActive({ id: d.bankAccountId }),
  });
  const [intlWireConfig, setIntlWireConfig] = useState<InternationalWireBillingConfig>();
  const { isLoading: isIntlWireConfigLoading } = useGetPlatformIntlWireConfig({
    onSuccess: (data) => {
      setTimeout(() => setIntlWireConfig(data), 0);

      if (data.revenueAccountNumberId) {
        getAccountNumberActive({ id: data.revenueAccountNumberId });
      }
    },
    onError: (error) => {
      addDangerNotification({
        content: error.message ?? 'Something went wrong getting International Wire Billing Config, please try again.',
      });
    },
  });
  const { createRequest: updateIntlWireConfig } = useUpdatePlatformIntlWireConfig({
    onSuccess: () => {
      addSuccessNotification({
        content: 'International Wire Billing Config updated successfully.',
      });
    },
    onError: (error) => {
      addDangerNotification({
        content: error.message ?? 'Something went wrong updating International Wire Billing Config, please try again.',
      });
    },
  });
  const openModal = useModalStore((state) => state.openModal);
  const openAlert = useAlertStore((state) => state.openAlert);
  const { id } = useParams<keyof Params>() as Params;
  const idRef = useRef<string>(id as string);
  const [platform, setPlatform] = useState<Partial<PlatformType>>();
  const [userList, setUserList] = useState<UserList[]>([]);
  const [inviteList, setInviteList] = useState<InviteList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [timezones, setTimezones] = useState<DropdownOption[]>([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);

  const hiddenBankAccounts = useMemo(() => {
    return bankAccountActive && bankAccounts?.bankAccounts.findIndex((b) => b.id === bankAccountActive.id) === -1
      ? [
          {
            label: `${bankAccountActive?.displayName || bankAccountActive.description} (${formatNumber(
              bankAccountActive?.balances?.availableAmount
            )})`,
            small: bankAccountActive.id,
            value: bankAccountActive.defaultAccountNumberId,
          },
        ]
      : [];
  }, [bankAccountActive, bankAccounts?.bankAccounts]);

  const deleteInvite = (platformId: string, email: string) => {
    InviteRepository.delete(platformId, email)
      .then(() => {
        fetchData();
        addSuccessNotification({
          content: 'Invite deleted',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const handleRoleChange = useCallback(
    (userId: string, email: string, role: PlatformRoleName) => {
      setUserList((list) => {
        const current = list.findIndex((u) => u.id === userId);

        if (current !== -1) {
          list[current].role = {
            id: userId,
            name: role,
            email,
          };
          return [...list];
        }

        return list;
      });

      UserRepository.updatePlatformRole(idRef.current, {
        userId,
        precannedRoleName: role,
      })
        .then(() => {
          fetchData();

          addSuccessNotification({
            content: `The role from "${email}" is updated to "${userGroups[role]}" successfully.`,
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [idRef.current]
  );

  const hanldeUpdateIntlWireConfig = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      updateIntlWireConfig(intlWireConfig);
    },
    [intlWireConfig]
  );

  const handleRemoveUser = useCallback((user: DashboardUserRedacted, platformId: string) => {
    UserRepository.removeUserFromPlatform({
      dashboardUserId: user.id,
      platformId,
    })
      .then(() => {
        fetchData();

        addSuccessNotification({
          content: `"${user.firstName} ${user.lastName}" successfully removed from this platform.`,
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, []);

  const handleUserRemoveConfirm = useCallback(
    (user: DashboardUserRedacted, platformId: string) => {
      openAlert({
        headline: `Remove ${user.firstName}?`,
        text: `Are you sure you want to remove ${user.firstName} ${user.lastName} from this platform?.`,
        submitText: 'Remove',
        callback: () => handleRemoveUser(user, platformId),
      });
    },
    [handleRemoveUser]
  );

  const handleUserDisableTotpMfa = useCallback(
    (user: DashboardUserRedacted, totpFactor: Factor) => {
      openAlert({
        headline: `Disable Authenticator App for ${user.firstName}`,
        text: `This action cannot be undone. If authenticator app two-factor authentication is disabled, it can
          only be re-enabled by ${user.firstName}.`,
        submitText: 'Disable',
        size: 'small',
        callback: () => {
          UserRepository.deleteFactor({ factorId: totpFactor?.factorId })
            .then(() => {
              addSuccessNotification({
                content: 'Authenticator app disabled',
              });
              setUserList((list) =>
                list.map((u) =>
                  u.id === user.id
                    ? { ...u, factors: (u.factors ?? []).filter((f) => f.factorId !== totpFactor.factorId) }
                    : u
                )
              );
            })
            .catch((error) => {
              addDangerNotification({
                content: error.message,
              });
            });
        },
      });
    },
    [currentUser]
  );

  const resendInvite = (email: string) => {
    InviteRepository.resend(email, idRef.current)
      .then(() => {
        addSuccessNotification({
          content: 'Invite resent',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchData = useCallback(() => {
    if (!currentUser) {
      return;
    }

    Promise.all([
      UserRepository.getAll(idRef.current).then((response) => {
        const entries: UserList[] = [];
        if (response.dashboardUsers.length) {
          response.dashboardUsers.map((entry) => {
            const smsFactor = entry.factors.find(
              (factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.SMS
            );
            const totpFactor = entry.factors.find(
              (factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.TOTP
            );

            entries.push({
              id: entry.id,
              info: {
                id: entry.id,
                name: `${entry.firstName} ${entry.lastName}`,
                isOwner: entry.platformRole.name === 'owner',
              },
              email: entry.email,
              factors: entry.factors,
              role: {
                id: entry.id,
                email: entry.email,
                name: entry.platformRole.name,
              },
              action: deviceMFAEnabled ? (
                <Dropdown
                  isInside
                  positionRight
                  options={[
                    {
                      label: 'Reset Authenticator 2FA',
                      isDisabled: !smsFactor || !totpFactor,
                      tooltip: !totpFactor
                        ? { content: 'User does not have Authenticator 2FA configured', placement: 'left' }
                        : !smsFactor
                          ? { content: 'Authenticator 2FA may not be reset without SMS 2FA enabled', placement: 'left' }
                          : undefined,
                      onClick: totpFactor ? () => handleUserDisableTotpMfa(entry, totpFactor) : undefined,
                    },
                    {
                      label: 'Remove',
                      isDanger: true,
                      isDisabled: currentUser.id === entry.id || entry.platformRole.name === 'owner',
                      onClick: () => handleUserRemoveConfirm(entry, entry.platformRole.platformId),
                    },
                  ]}
                  variant="dots"
                />
              ) : (
                <Button
                  variant="subtle"
                  size="small"
                  icon={<Icon.Trash />}
                  onClick={() => handleUserRemoveConfirm(entry, entry.platformRole.platformId)}
                  isDisabled={currentUser.id === entry.id || entry.platformRole.name === 'owner'}
                />
              ),
            });
          });
        }
        setUserList(entries);
      }),
      InviteRepository.getAll(idRef.current).then((response) => {
        const entries: InviteList[] = [];
        if (response.invites.length) {
          response.invites.map((entry) => {
            entries.push({
              id: entry.platformId,
              email: entry.email,
              role: entry.platformRole,
              created: entry.createdAt,
              action: (
                <Dropdown
                  isInside
                  positionRight
                  options={[
                    {
                      label: 'Resend',
                      onClick: () => resendInvite(entry.email),
                    },
                    {
                      label: 'Delete',
                      onClick: () => deleteInvite(entry.platformId, entry.email),
                      isDanger: true,
                    },
                  ]}
                  variant="dots"
                />
              ),
            });
          });
        }
        setInviteList(entries);
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((e) => console.error('InviteRepository.getAll', e));
  }, [currentUser, deviceMFAEnabled, idRef.current]);

  const handleOpenInviteModal = useCallback(() => {
    openModal('Invite', { platformId: idRef.current, callback: () => setTimeout(fetchData, 50) });
  }, [idRef.current, fetchData]);

  const handlePlatformUpdate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!idRef.current || !currentUser || !platform) {
      return;
    }

    if (currentPlatform?.id === idRef.current && (platform.name || platform.reportingTimeZone)) {
      currentPlatform
        .update({ name: platform.name, reportingTimeZone: platform.reportingTimeZone })
        .then(() => {
          addSuccessNotification({
            content: 'Platform saved',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
      return;
    }

    const data = { platformId: idRef.current, name: platform.name, reportingTimeZone: platform.reportingTimeZone };

    PlatformRepository.update(data as UpdatePlatform)
      .then(() => {
        addSuccessNotification({
          content: 'Platform saved',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchInit = () => {
    if (location.pathname === ROUTE.PLATFORM_SETTINGS && currentUser?.defaultPlatformId) {
      idRef.current = currentUser?.defaultPlatformId;
    }

    if (idRef.current && currentUser) {
      setIsLoading(true);
      PlatformRepository.get(idRef.current)
        .then(setPlatform)
        .catch((e) => console.error('PlatformRepository.get', e))
        .finally(() => setIsLoading(false));
      fetchData();
    }
  };

  useEffect(() => {
    fetchInit();
  }, [idRef.current]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => fetchInit(),
        {
          fireImmediately: true,
        }
      ),
    []
  );

  useEffect(() => {
    import('~/data/timezones.json').then((json) => {
      const entries: DropdownOption[] = Object.keys(json.default).map((t) => ({
        label: t,
        small: json.default[t as keyof typeof json.default],
        value: t,
      }));

      entries.splice(1, 0, {
        isDivider: true,
      });

      setTimezones(entries);
    });
  }, []);

  const userColumns: TableColumn[] = [
    {
      Header: 'Name',
      accessor: 'info',
      width: 'minmax(300px, auto)',
      Cell: (current: { value: UserListInfo }) => (
        <StyledTruncate>
          {current.value.name}
          {(current.value.id === currentUser?.id || current.value.isOwner) && (
            <UserInfo>
              {current.value.id === currentUser?.id && <Chip type="info">You</Chip>}
              {current.value.isOwner && <Chip>Owner</Chip>}
            </UserInfo>
          )}
        </StyledTruncate>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 'min-content',
      Cell: (current) => <Truncate>{current.value}</Truncate>,
    },
    {
      Header: '2FA',
      accessor: 'factors',
      width: 'min-content',
      Cell: (current) => {
        if (!current.value || current.value.length === 0) {
          return <Chip>Not enabled</Chip>;
        }
        const factors = current.value as Factor[];
        if (factors.some((factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.TOTP)) {
          return <Chip type={'success'}>Authenticator</Chip>;
        }
        if (factors.some((factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.SMS)) {
          return <Chip type={'success'}>SMS</Chip>;
        }
        return null;
      },
    },
    {
      Header: () => (
        <RoleHelpButton
          icon={<Icon.CircleQuestionmark />}
          onClick={() => openHelpSidebar('Roles')}
          variant="subtle"
          size="small"
        >
          Role
        </RoleHelpButton>
      ),
      accessor: 'role',
      width: 'minmax(200px, min-content)',
      Cell: (current: { value: UserListRole }) => (
        <Dropdown
          size="small"
          active={current.value.name === 'owner' ? 'admin' : current.value.name}
          onChange={(value: PlatformRoleName) => {
            handleRoleChange(current.value.id, current.value.email, value);
          }}
          fullWidth
          isDisabled={
            (currentPermission?.platformInfo !== 'write' && idRef.current === currentUser?.defaultPlatformId) ||
            currentUser?.id === current.value.id ||
            (current.value.name === 'owner' && idRef.current === currentUser?.defaultPlatformId)
          }
          options={
            currentUser?.id === current.value.id
              ? userGroupsDropdown
              : userGroupsDropdown.filter((g) => g.value !== 'owner')
          }
        />
      ),
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  const inviteColumns: TableColumn[] = [
    {
      Header: 'Email',
      accessor: 'email',
      width: 'minmax(300px, auto)',
      Cell: (current) => <Truncate>{current.value}</Truncate>,
    },
    {
      Header: () => (
        <RoleHelpButton
          icon={<Icon.CircleQuestionmark />}
          onClick={() => openHelpSidebar('Roles')}
          variant="subtle"
          size="small"
        >
          Role
        </RoleHelpButton>
      ),
      accessor: 'role',
      width: 'minmax(116px, min-content)',
      Cell: (current: { value: PlatformRoleName }) => <>{userGroups[current.value]}</>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader text="Settings" />
      <StyledFade show={isLoading || isIntlWireConfigLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading && !isIntlWireConfigLoading}>
        {id && (
          <>
            <SectionHeader text={platform?.name || 'Default'} />
          </>
        )}

        <Grid gap={0}>
          <PrimaryColumn>
            <SectionHeader text="General" border={id ? true : undefined} />
            <Inner pb={48}>
              <form onSubmit={handlePlatformUpdate}>
                <Grid vertical>
                  <FormElement>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={platform?.name ?? ''}
                      onChange={(value: string) => setPlatform({ ...platform, name: value })}
                      placeholder="Name"
                    />
                  </FormElement>
                  <FormElementAction>
                    <FormElement>
                      <FormLabel>
                        Reporting Time Zone{' '}
                        <a href="https://column.com/docs/guides/reporting" target="_blank" rel="noreferrer">
                          Guide
                        </a>
                      </FormLabel>
                      <Dropdown
                        variant="muted"
                        fullWidth
                        active={platform?.reportingTimeZone ?? ''}
                        onChange={(value: string) => {
                          setPlatform({ ...platform, reportingTimeZone: value });
                        }}
                        options={timezones}
                        search
                      />
                    </FormElement>
                    <Action>
                      <Button>Save</Button>
                    </Action>
                  </FormElementAction>

                  <Grid vertical>
                    <FormElement>
                      <FormLabel>Created</FormLabel>
                      <FormText>{platform?.createdAt && getDateLongUTC(platform.createdAt as any)}</FormText>
                    </FormElement>
                    <FormElement>
                      <FormLabel>Platform Level</FormLabel>
                      <Chip>{formatString(platform?.platformLevel ?? '')}</Chip>
                    </FormElement>
                  </Grid>
                </Grid>
              </form>
            </Inner>
          </PrimaryColumn>

          <div>
            <SectionHeader text="International Wire Billing Config" border={id ? true : undefined} />
            <Inner>
              <form onSubmit={hanldeUpdateIntlWireConfig}>
                <Grid vertical>
                  <FormElement>
                    <FormLabel>
                      Revenue Account Number
                      <FormLabelTooltip content="Account number ID for international wire revenues of a platform">
                        <Icon.CircleQuestionmark />
                      </FormLabelTooltip>
                    </FormLabel>
                    <Dropdown
                      options={
                        bankAccounts?.bankAccounts.map((bankAccount) => ({
                          label: `${
                            bankAccount?.displayName && bankAccount?.description
                              ? `${bankAccount.displayName} – ${bankAccount.description}`
                              : bankAccount?.description || 'Unnamed'
                          } (${formatNumber(bankAccount?.balances?.availableAmount)})`,
                          small: bankAccount.id,
                          value: bankAccount.defaultAccountNumberId,
                        })) ?? []
                      }
                      hiddenOptions={hiddenBankAccounts}
                      fullWidth
                      maxWidth="640px"
                      active={intlWireConfig?.revenueAccountNumberId ?? ''}
                      search
                      searchLabel="Search for description"
                      onSearchChange={(description: string) => searchBankAccounts({ description })}
                      onChange={(value) => setIntlWireConfig((state) => ({ ...state, revenueAccountNumberId: value }))}
                    />
                  </FormElement>
                  <Grid>
                    <FormElement>
                      <FormLabel>Fixed Fee</FormLabel>
                      <StyledAmountInput
                        value={intlWireConfig?.fixedFee ? intlWireConfig.fixedFee : undefined}
                        onChange={(value) => setIntlWireConfig((state) => ({ ...state, fixedFee: value }))}
                        placeholder="Fixed Fee"
                        currencyList={CurrenciesUSD}
                        fixedCurrencyCode="USD"
                        currencyCode="USD"
                      />
                    </FormElement>
                    <FormElement>
                      <FormLabel>
                        FX Rate Margin BPS
                        <FormLabelTooltip content="Number of basis points (BPS) for FX margin. 1 bps = 0.01%">
                          <Icon.CircleQuestionmark />
                        </FormLabelTooltip>
                      </FormLabel>
                      <NumberInput
                        value={intlWireConfig?.fxRateMarginBps ? String(intlWireConfig.fxRateMarginBps) : ''}
                        onChange={(value) =>
                          setIntlWireConfig((state) => ({ ...state, fxRateMarginBps: Number(value) }))
                        }
                        placeholder="FX Rate Margin BPS"
                      />
                    </FormElement>
                  </Grid>
                  <SaveToolbar>
                    <Button size="small">Update</Button>
                  </SaveToolbar>
                </Grid>
              </form>
            </Inner>
          </div>
        </Grid>

        <Relative>
          <Fade show={loading} base={StyledLoading} />
          <Fade show={!loading}>
            <SectionHeader text="Users" border />
            <Inner pt={0} px={0}>
              <StyledTable columns={userColumns} data={userList} />
            </Inner>
            <Line />
            <SectionHeader text="Pending Invites">
              <Button onClick={handleOpenInviteModal} icon={<Icon.Mail />} size="small">
                Invite User
              </Button>
            </SectionHeader>

            <StyledTable columns={inviteColumns} data={inviteList} />
          </Fade>
        </Relative>
      </Fade>
    </>
  );
};
